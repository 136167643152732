import Vue from "vue";
import axios from "axios";

const state = {
    faqs: []
};
const getters = {
    getFaqs(state) {
        return state.faqs;
    }
};

const mutations = {
    setfaqs(state, data) {
        state.faqs = data.data;
    },
};
const actions = {
    
    loadFaqs(context){
        var faqs = [{
            'toggle':'true',
            'id':1,
            'question' :'Why do I need to register?',
            'answer':'The Retail Hub is home to exclusive store operations & retail marketing resources and opportunities for Google’s & our associated partners. Not just anybody may use our Retail Hub – you must have an existing partnership with Google. In order to determine your access levels, we need some information from you to determine this.'
        },{
            'toggle':'false',
            'id':2,
            'question' :'How long does it take to receive access?',
            'answer':'Our team needs 1-2 business day to review your application and verify your partnership with Google. If you sign up with an email address, be sure to verify your email address immediately to avoid any delays in our review. If you haven’t verified your email address your application will not be submitted for approval.'
        },{
            'toggle':'false',
            'id':3,
            'question' :'How do I verify my email address?',
            'answer':'If you have authenticated with an email address that is not associated with a Google account, we’ve sent an email to verify your email address. Please check your inbox and look for an email from noreply@retailhub.withgoogle.com – be sure to check your spam folders.'
        },{
            'toggle':'false',
            'id':4,
            'question' :'My application has been rejected.',
            'answer':'Our team has reviewed your application and wasn’t able to confirm you have an ongoing partnership with Google. Please note that we only allow you to apply with your corporate email address. If you have signed up with a personal email address (@gmail.com, @outlook.com, etc.) – please log out and apply for access with your corporate account. You can log out by navigating to “My account” in the top right, and then clicking the “Log out” button in the lower left corner of the page.'
        },{
            'toggle':'false',
            'id':5,
            'question' :'I have been granted access, but am still not able to view some of the content.',
            'answer':'There are several reasons why this may be the case. To verify which material you have access to, please check the “My account” page. If you need access to a material that is not listed there, you can request additional access. If you believe that you have been granted access and should be able to view the respective content, please try to refresh the page. Your new access levels may not have been updated.'
        },{
            'toggle':'false',
            'id':6,
            'question' :'Are access permissions transferable?',
            'answer':'No, access permissions and account approvals are not transferable.'
        },{
            'toggle':'false',
            'id':7,
            'question' :'What is a trademark?',
            'answer':'A trademark is a word, name, symbol or device (or a combination thereof) that identifies the goods or services of a person or company and distinguishes them from the goods and services of others. In other words, a trademark is what makes a brand unique. Trademarks and logo’s throughout the Retail Hub are owned by Google LLC.'
        }];
        context.commit("setfaqs", { data: faqs });
        // axios
        //     .get("/api/myorders/"+id)
        //     .then(response => {
        //         context.commit("setProductInfo", { data: response.data });
        //     })
        //     .catch(error => {
        //         context.commit("setProductInfo", { data: [] });
        //     });
    }
};
export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
};
