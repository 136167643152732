import Vue from "vue";
import vueRouter from "vue-router";
// import NotFound from './components/NotFound';
const NotFound = () =>
  import(/* webpackChunkName: 'general' */ "./components/NotFound.vue");
const Mobileversion = () =>
  import(/* webpackChunkName: 'general' */ "./components/Mobileversion.vue");

// import Home from './pages/home';
const Home = () => import(/* webpackChunkName: 'general' */ "./pages/home.vue");
// import Configuration from './pages/configuration';
const Configuration = () =>
  import(/* webpackChunkName: 'Configuration' */ "./pages/configuration.vue");
// import Login from './pages/auth/login';
const Login = () =>
  import(/* webpackChunkName: 'general' */ "./pages/auth/login.vue");
// import VerifyOTP from './pages/auth/otpVerify';
const VerifyOTP = () =>
  import(/* webpackChunkName: 'general' */ "./pages/auth/otpVerify.vue");

// import Profile from './pages/users/profile';
const Profile = () =>
  import(/* webpackChunkName: 'general' */ "./pages/users/profile.vue");
// import Notification from './pages/users/notification';
const Notification = () =>
  import(/* webpackChunkName: 'general' */ "./pages/users/notification.vue");
// import ManageProfile from './pages/users/manage-profile';
const ManageProfile = () =>
  import(/* webpackChunkName: 'general' */ "./pages/users/manage-profile.vue");
const ManageUserProfile = () =>
  import(
    /* webpackChunkName: 'general' */ "./pages/users/manage-user-profile.vue"
  );
// import VerifyEmail from './pages/messages/email-verify';
const VerifyEmail = () =>
  import(/* webpackChunkName: 'general' */ "./pages/messages/email-verify.vue");
// import EmailVerified from './pages/messages/email-verified';
const EmailVerified = () =>
  import(
    /* webpackChunkName: 'general' */ "./pages/messages/email-verified.vue"
  );
// import RequestAccess from './pages/users/requestAccess';
const RequestAccess = () =>
  import(/* webpackChunkName: 'general' */ "./pages/users/requestAccess.vue");
// import RequestSent from './pages/messages/request-sent';
const RequestSent = () =>
  import(/* webpackChunkName: 'general' */ "./pages/messages/request-sent.vue");
// import UserList from './pages/users/user-list';
const UserList = () =>
  import(/* webpackChunkName: 'admin' */ "./pages/users/user-list.vue");
// import ChangePassword from './pages/users/change-password';
// const ChangePassword = () =>
//   import(/* webpackChunkName: 'general' */ './pages/users/change-password')
// import Products from './pages/products/products';
const Products = () =>
  import(/* webpackChunkName: 'Products' */ "./pages/products/products.vue");
// import WarehouseInventory from './pages/warehouse/warehouse-inventory';
const WarehouseInventory = () =>
  import(
    /* webpackChunkName: 'WarehouseInventory' */
    "./pages/warehouse/warehouse-inventory.vue"
  );
// import NewShipment from './pages/warehouse/new-shipment';
const NewShipment = () =>
  import(
    /* webpackChunkName: 'WarehouseInventory' */
    "./pages/warehouse/new-shipment.vue"
  );
// import Shipment from './pages/warehouse/shipment';
const Shipment = () =>
  import(
    /* webpackChunkName: 'WarehouseInventory' */
    "./pages/warehouse/shipment.vue"
  );
const ShipmentPrint = () =>
  import(
    /* webpackChunkName: 'WarehouseInventory' */
    "./pages/warehouse/shipment-print.vue"
  );
// import NewProduct from './pages/products/new-product';
const NewProduct = () =>
  import(/* webpackChunkName: 'Products' */ "./pages/products/new-product.vue");
const test = () =>
  import(/* webpackChunkName: 'Products' */ "./pages/products/test.vue");
// import faqs from './pages/faqs';
const faqs = () => import(/* webpackChunkName: 'general' */ "./pages/faqs.vue");
// import ProductOverview from './pages/products/product-overview';
const ProductOverview = () =>
  import(
    /* webpackChunkName: 'Products' */
    "./pages/products/product-overview.vue"
  );

const PrintProductOverview = () =>
  import(
    /* webpackChunkName: 'Products' */ "./pages/products/product-print.vue"
  );
// import Fixtures from './pages/fixtures/fixtures';
const Fixtures = () =>
  import(/* webpackChunkName: 'Fixtures' */ "./pages/fixtures/fixtures.vue");
// import NewFixture from './pages/fixtures/new-fixture';
const NewFixture = () =>
  import(/* webpackChunkName: 'Fixtures' */ "./pages/fixtures/new-fixture.vue");
// import FixtureOverview from './pages/fixtures/fixture-overview';
const FixtureOverview = () =>
  import(
    /* webpackChunkName: 'Fixtures' */
    "./pages/fixtures/fixture-overview.vue"
  );
const FixtureOverviewPrint = () =>
  import(
    /* webpackChunkName: 'Fixtures' */
    "./pages/fixtures/fixture-overview-print-ready.vue"
  );
const PrototypePrint = () =>
  import(
    /* webpackChunkName: 'Fixtures' */
    "./pages/prototype/cmp/prototype-print-ready.vue"
  );
// import FixtureMarketOverview from './pages/fixtures/fixture-market-overview';
const FixtureMarketOverview = () =>
  import(
    /* webpackChunkName: 'marketoverview' */
    "./pages/fixtures/fixture-market-overview.vue"
  );
// import NewPos from './pages/pos/new-pos';
const NewPos = () =>
  import(/* webpackChunkName: 'pos' */ "./pages/pos/new-pos.vue");
// import PosList from './pages/pos/pos-list';
const PosList = () =>
  import(/* webpackChunkName: 'pos' */ "./pages/pos/pos-list.vue");
// import VendorList from './pages/vendors/vendor-list';
const VendorList = () =>
  import(
    /* webpackChunkName: 'retail-vendor' */ "./pages/vendors/vendor-list.vue"
  );
// import VendorProfile from './pages/vendors/profile';
const VendorProfile = () =>
  import(/* webpackChunkName: 'retail-vendor' */ "./pages/vendors/profile.vue");
// import NewVendor from './pages/vendors/new-vendor';
const NewVendor = () =>
  import(
    /* webpackChunkName: 'retail-vendor' */ "./pages/vendors/new-vendor.vue"
  );
// import Prototype from './pages/prototype/prototypes';
const Prototype = () =>
  import(
    /* webpackChunkName: 'Prototype' */ "./pages/prototype/prototypes.vue"
  );
// import NewPrototype from './pages/prototype/new-prototype';
const NewPrototype = () =>
  import(
    /* webpackChunkName: 'Prototype' */ "./pages/prototype/new-prototype.vue"
  );
// import BriefOverview from './pages/brief/overview';
const BriefOverview = () =>
  import(/* webpackChunkName: 'brief' */ "./pages/brief/overview.vue");
import Test from "./components/filepond.vue";
// const VerifyOTP = () =>
//   import(/* webpackChunkName: 'general' */ './pages/auth/otpVerify')
// import CreateBriefing from './pages/brief/createBriefing';
const CreateBriefing = () =>
  import(/* webpackChunkName: 'brief' */ "./pages/brief/createBriefing.vue");
// import SelectFixture from './pages/brief/selectFixture';
const SelectFixture = () =>
  import(/* webpackChunkName: 'brief' */ "./pages/brief/selectFixture.vue");
// import BriefPreview from './pages/brief/preview';
const BriefPreview = () =>
  import(/* webpackChunkName: 'brief' */ "./pages/brief/preview.vue");
// import Briefing from './pages/briefing/briefing';
const Briefing = () =>
  import(/* webpackChunkName: 'brief' */ "./pages/briefing/briefing.vue");
// import StorelistOverview from './pages/storelist/overview';
const StorelistOverview = () =>
  import(/* webpackChunkName: 'storelist' */ "./pages/storelist/overview.vue");
// import StoreViewlist from '`./pages/storelist/viewList`';
const StoreViewlist = () =>
  import(/* webpackChunkName: 'storelist' */ "./pages/storelist/viewList.vue");

// import MarketOverview from './pages/marketOverview/market-overview';
const MarketOverview = () =>
  import(
    /* webpackChunkName: 'marketoverview' */
    "./pages/marketOverview/market-overview.vue"
  );
// import ProductMarketOverview from './pages/productOverview/product-overview';
const ProductMarketOverview = () =>
  import(
    /* webpackChunkName: 'marketoverview' */
    "./pages/productOverview/product-overview.vue"
  );

// import Assumption from './pages/cost_assumptions/assumption';
const Assumption = () =>
  import(
    /* webpackChunkName: 'Assumption' */
    "./pages/cost_assumptions/assumption.vue"
  );
// import AssumptionOverview from './pages/cost_assumptions/overview';
const AssumptionOverview = () =>
  import(
    /* webpackChunkName: 'Assumption' */
    "./pages/cost_assumptions/overview.vue"
  );
// import Planogram from './pages/planogram/overview';
const Planogram = () =>
  import(/* webpackChunkName: 'Planogram' */ "./pages/planogram/overview.vue");
// import DesignPlanogram from './pages/planogram/designPlanogram'

const PlanogramFolder = () => import("./pages/planogram/openFolder.vue")

const DesignPlanogram = () =>
  import(
    /* webpackChunkName: 'Planogram' */
    "./pages/planogram/designPlanogram.vue"
  );

const DesignPlanogramKova = () =>
  import(
    /* webpackChunkName: 'Planogram' */
    "./pages/planogram/designPlanogramKova.vue"
  );

const TestKova = () =>
  import(
    /* webpackChunkName: 'Planogram' */
    "./pages/planogram/test.vue"
  );

const LoggedIn = () =>
  import(/* webpackChunkName: 'Planogram' */ "./pages/loggedin.vue");

let routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
  // {
  //   path: "/verify/:token",
  //   name: "verify",
  //   component: VerifyOTP,
  // },
  {
    path: "/complete-profile",
    name: "request",
    component: RequestAccess,
  },
  // {
  //   path: "/request-sent",
  //   name: "request-sent",
  //   component: RequestSent,
  // },
  {
    path: "/verify-your-email",
    name: "email-verify",
    component: VerifyEmail,
  },
  // {
  //   path: "/email-verified/:token",
  //   name: "email-verified",
  //   component: EmailVerified,
  // },
  {
    path: "/faqs",
    name: "faqs",
    component: faqs,
  },
  {
    path: "/app",
    component: LoggedIn,
    redirect: "/app/home",
    children: [
      {
        path: "home",
        name: "home",
        component: Home,
      },

      {
        path: "configuration",
        name: "configuration",
        component: Configuration,
      },
      {
        path: "profile",
        name: "profile",
        component: Profile,
      },
      {
        path: "notification",
        name: "notification",
        component: Notification,
      },
      {
        path: "manage-profile",
        name: "manage-profile",
        component: ManageProfile,
      },
      {
        path: "manage-profile/:id",
        name: "manage-user-profile",
        component: ManageUserProfile,
      },
      {
        path: "user-list",
        name: "user-list",
        component: UserList,
      },
      {
        path: "products",
        name: "products",
        component: Products,
      },
      {
        path: "test",
        name: "test",
        component: test,
        meta: {
          bordergone: false,
        },
      },
      {
        path: "warehouse/inventory",
        name: "warehouse-inventory",
        component: WarehouseInventory,
      },
      {
        path: "warehouse/shipment",
        name: "warehouse-shipment",
        component: WarehouseInventory,
      },
      {
        path: "warehouse/shipment/:id",
        name: "warehouse-shipment-view",
        component: Shipment,
      },
      {
        path: "warehouse/shipment/print/:id",
        name: "warehouse-shipment-print",
        component: ShipmentPrint,
      },
      {
        path: "warehouse/new-shipment",
        name: "new-shipment",
        component: NewShipment,
      },
      {
        path: "warehouse/edit-shipment/:id",
        name: "edit-shipment",
        component: NewShipment,
      },
      {
        path: "prototype/list",
        name: "Prototype-list",
        component: Prototype,
      },
      {
        path: "prototype/new",
        name: "Prototype-new",
        component: NewPrototype,
      },
      {
        path: "prototype/edit/:id",
        name: "Prototype-edit",
        component: NewPrototype,
      },
      {
        path: "new-product",
        name: "new-product",
        component: NewProduct,
      },
      {
        path: "edit-product/:id",
        name: "edit-product",
        component: NewProduct,
      },
      {
        path: "duplicate-product/:id",
        name: "duplicate-product",
        component: NewProduct,
      },
      {
        path: "product-overview/:id",
        name: "product-overview",
        component: ProductOverview,
      },
      {
        path: "product/print/:id",
        name: "product-print-overview",
        component: PrintProductOverview,
      },
      {
        path: "vendor/list",
        name: "vendors",
        component: VendorList,
      },
      {
        path: "vendor/new",
        name: "new-vendor",
        component: NewVendor,
      },
      {
        path: "vendor/test",
        name: "new-test",
        component: Test,
      },
      {
        path: "vendor/edit/:id",
        name: "edit-vendor",
        component: NewVendor,
      },
      {
        path: "vendor/profile/:id",
        name: "vendor-profile",
        component: VendorProfile,
      },
      {
        path: "fixture/list",
        name: "fixtures",
        component: Fixtures,
      },
      {
        path: "fixture/new",
        name: "new-fixture",
        component: NewFixture,
      },
      {
        path: "fixture/edit/:id",
        name: "edit-fixture",
        component: NewFixture,
      },
      {
        path: "fixture-overview/:id",
        name: "fixture-overview",
        component: FixtureOverview,
      },
      {
        path: "fixture-overview-print/:id",
        name: "fixture-overview-print",
        component: FixtureOverviewPrint,
        meta: {
          bordergone: false,
        },
      },
      {
        path: "prototype-print/:id",
        name: "prototype-print",
        component: PrototypePrint,
        meta: {
          bordergone: false,
        },
      },
      {
        path: "fixture-market-overview",
        name: "fixture-market-overview",
        component: FixtureMarketOverview,
      },
      {
        path: "pos/new",
        name: "newpos",
        component: NewPos,
      },
      {
        path: "pos/list",
        name: "poslist",
        component: PosList,
      },
      {
        path: "brief/overview",
        name: "BriefOverview",
        component: BriefOverview,
      },
      {
        path: "brief/createBriefing",
        name: "CreateBriefing",
        component: CreateBriefing,
      },
      {
        path: "brief/fixture",
        name: "SelectFixture",
        component: SelectFixture,
      },
      {
        path: "brief/preview/:id",
        name: "brief-preview",
        component: BriefPreview,
      },
      {
        path: "brief/edit/:id",
        name: "brief-preview-edit",
        component: CreateBriefing,
      },
      {
        path: "briefing/briefing",
        name: "Briefing",
        component: Briefing,
      },
      {
        path: "storelist/overview",
        name: "StorelistOverview",
        component: StorelistOverview,
      },
      {
        path: "storelist/view/:id",
        name: "StoreViewlist",
        component: StoreViewlist,
      },
      {
        path: "market-overview",
        name: "MarketOverview",
        component: MarketOverview,
      },
      {
        path: "product-market-overview",
        name: "ProductMarketOverview",
        component: ProductMarketOverview,
      },
      {
        path: "planogram",
        name: "Planogram",
        component: Planogram,
      },
      {
        path: "planogram/folder/:id",
        name: "Planogramfolder",
        component: PlanogramFolder,
      },
      {
        path: "planogram/designer/:id",
        name: "DesignPlanogram",
        component: DesignPlanogramKova,
        meta: {
          bordergone: false,
        },
      },
      {
        path: "planogram/test/:id",
        name: "DesignPlanogram",
        component: TestKova,
        meta: {
          bordergone: false,
        },
      },
      {
        path: "planogram/print/:id",
        name: "DesignPlanogramprint",
        component: DesignPlanogram,
        meta: {
          bordergone: false,
        },
      },
      {
        path: "assumption/overview",
        name: "assumption-overview",
        component: AssumptionOverview,
      },
      {
        path: "assumption/main",
        name: "main-assumption",
        component: Assumption,
      },
    ],
  },
  {
    path: "/mobile-view",
    name: "Mobileversion",
    component: Mobileversion,
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound,
  },
];

Vue.use(vueRouter);
const router = new vueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
