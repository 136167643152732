import Vue from "vue";
import axios from "axios";

const state = {
  products: [],
  product_info: [],
  family: [],
  planoproducts: [],
  category: null,
  product_name: null,
  response: null,
  fixture_products: [],
  checkresponse: "",
  editproduct_info: [],
};
const getters = {
  getProducts(state) {
    var a = state.products;
    a = a.filter((x) => {
      if (state.category != null) {
        if (x.familyTypeId == state.category.id) {
          return true;
        }
      } else if (state.product_name != null) {
        if (
          x.product_name
            .toLowerCase()
            .trim()
            .lastIndexOf(state.product_name.toLowerCase().trim(), 0) === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
    // console.log("a", a);
    return a;
  },
  getProInfo(state) {
    return state.product_info;
  },
  geteditProInfo(state) {
    return state.editproduct_info;
  },
  getFixtureProducts(state) {
    return state.fixture_products;
  },
  getResponse(state) {
    return state.response;
  },
  getcheckresponse(state) {
    return state.checkresponse;
  },
  getFamily(state) {
    return state.family;
  },
  getPlanoproducts(state) {
    return state.planoproducts;
  },
};

const mutations = {
  setproduct(state, data) {
    state.products = data.data;
  },
  setFixtureProduct(state, data) {
    state.fixture_products = data.data;
  },
  setCategory(state, data) {
    state.product_name = null;
    state.category = data;
  },
  setProductName(state, data) {
    state.category = null;
    state.product_name = data;
  },
  setProductInfo(state, data) {
    state.product_info = data.data[0];
  },
  seteditProductInfo(state, data) {
    state.editproduct_info = data.data[0];
  },
  setFamily(state, data) {
    state.family = data.data;
  },
  setResponse(state, data) {
    state.response = data.data;
  },
  setcheckresponse(state, data) {
    state.checkresponse = data.data;
  },
  setplanoproduct(state, data) {
    state.planoproducts = data.data;
  },
};
const actions = {
  setCategory(context, data) {
    context.commit("setCategory", data);
  },
  setProductName(context, data) {
    context.commit("setProductName", data);
  },

  createFamily(context, data) {
    return axios
      .post("/api/product/product-family/create", data)
      .then((response) => {
        // axios
        //   .get("/api/product/product-family/getAll", {
        //     responseType: "json",
        //   })
        //   .then(function (response) {
        //     context.commit("setFamily", {
        //       data: response.data.data
        //     });
        //   });
        // context.commit("setResponse", {
        //   data: response.data
        // });
      })
      .catch((error) => {
        context.commit("setResponse", {
          data: [error],
        });
      });
  },
  createProduct(context, data) {
    // console.log("payload data", data);
    return axios
      .post("/api/product/create", data)
      .then((response) => {
        context.commit("setResponse", {
          data: response.data,
        });
      })
      .catch((error) => {
        // context.commit("setResponse", {
        //   data: [error],
        // });
        context.commit("setcheckresponse", {
          data: error.response,
        });
      });
  },
  loadFamily(context) {
    return axios
      .get("/api/product/product-family/getAll", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setFamily", {
          data: response.data.data,
        });
      });
  },
  loadproducts(context) {
    return axios
      .get("/api/product/getAll", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setproduct", {
          data: response.data.data,
        });
      });
  },
  loadproductsimple(context) {
    return axios
      .get("/api/product/getAll-simplified", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setproduct", {
          data: response.data.data,
        });
      });
  },
  loadproductsforfixture(context) {
    return axios
      .get("/api/product/fixture-get-all", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setproduct", {
          data: response.data.data,
        });
      });
  },
  productinfo(context, id) {
    return axios
      .get(`/api/product/${id}`, {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setProductInfo", {
          data: response.data.data,
        });
      });
  },
  editproductinfo(context, id) {  /// new product info api 
    return axios
      .get(`/api/product/${id}/edit`, {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("seteditProductInfo", {
          data: response.data.data,
        });
      });
  },
  deleteProduct(context, id) {
    axios
      .get(`/api/product/${id}/delete`, {
        responseType: "json",
      })
      .then(function (response) {
        axios
          .get("/api/product/getAll", {
            responseType: "json",
          })
          .then(function (response) {
            context.commit("setproduct", {
              data: response.data.data,
            });
          });
      });
  },
  deleteFamily(context, id) {
    // console.log("family del", id);
    axios
      .get(`/api/product/product-family/${id}/delete`, {
        responseType: "json",
      })
      .then(function (response) {
        axios
          .get("/api/product/product-family/getAll", {
            responseType: "json",
          })
          .then(function (response) {
            context.commit("setFamily", {
              data: response.data.data,
            });
          });
        context.commit("setResponse", {
          data: response,
        });
      });
  },
  updateFamily(context, data) {
    return axios
      .post("/api/product/product-family/update", data)
      .then((response) => {
        // axios
        //   .get("/api/product/product-family/getAll", {
        //     responseType: "json",
        //   })
        //   .then(function (response) {
        //     context.commit("setFamily", {
        //       data: response.data.data
        //     });
        //   });
        context.commit("setResponse", {
          data: response.data,
        });
      })
      .catch((error) => {
        context.commit("setResponse", {
          data: [error],
        });
      });
  },

  loadFixtureProducts(context, data) {
    return axios
      .post("/api/product/get-fixture-products", data)
      .then((response) => {
        context.commit("setFixtureProduct", {
          data: response.data,
        });
      })
      .catch((error) => {
        context.commit("setResponse", {
          data: [error],
        });
      });
  },
  printfixture(context, data) {
    return axios.post("/api/product/get-pdf/" + data);
  },
  removeFilePondImage(context, data) {
    return axios.post("/api/raven/revert", { data: data });
  },

  loadplanoproducts(context, id) {
    return axios
      .get(`/api/product/${id}/planogramproducts`)
      .then((response) => {
        context.commit("setplanoproduct", { data: response.data });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
