import "vite/modulepreload-polyfill";
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./routes";
import OtpInput from "@bachdgvn/vue-otp-input";
import * as Sentry from "@sentry/vue";
//material vue imports
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Tabs, Tab } from "vue-tabs-component";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import mitt from "mitt";
import VueTour from 'vue-tour'
import "./app.scss";
import "./app.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
const loading = () =>
  import(/* webpackPrefetch: true */ "../src/components/loading.vue");

const nodata = () => import("../src/components/empty_data.vue");
// import excel from 'vue-excel-export'
import VTooltip from "v-tooltip";
import 'vue-tour/dist/vue-tour.css'
import VueCookies from 'vue-cookies'

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, { expires: '7000d' })

Vue.use(VueTour)
Vue.use(ElementUI);
Vue.use(VTooltip);
// Vue.use(excel)
Vue.use(VueMaterial);
Vue.component("v-otp-input", OtpInput);
Vue.component("v-select", vSelect);
Vue.component("tabs", Tabs);
Vue.component("tab", Tab);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
const emitter = mitt();

Vue.prototype.$emitter = emitter;
Vue.use(LottieVuePlayer);
Vue.component("loading", loading);
Vue.component("nodata", nodata);

import IdleVue from "idle-vue";
//to be fixed
// let token = document.querySelector("[name=csrfmiddlewaretoken]").value;
// function readCookie(name) {
//   var nameEQ = name + '='
//   var ca = document.cookie.split(';')
//   for (var i = 0; i < ca.length; i++) {
//     var c = ca[i]
//     while (c.charAt(0) == ' ') c = c.substring(1, c.length)
//     if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
//   }
//   return null
// }

// var token = readCookie('csrftoken')
// if (token) {
//   axios.defaults.headers.common['X-CSRFToken'] = token
// } else {
//   console.error('CSRF token not found:')
// }

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
Vue.prototype.$ASSET_PATH = "/static/assets/img/";
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: "https://52a8ba6144131143c985acc0a243741f@watcher.boosoft.com.au/4",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/retail-hub\.app/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
new Vue({
  router: router,
  store: store,
  render: (h) => h(App),
}).$mount("#app");

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1000 * 60 * 60, // 3 seconds
  startAtIdle: false,
});
// Vue.use(IdleVue, {
//   eventEmitter: eventsHub,
//   store,
//   idleTime: 3000, // 3 seconds
//   startAtIdle: false
// });
