import axios from "axios";

const state = {
  planograms: [],
  normalizedplanogramdata: {},
  multiplyer: 4,
  planogram: {
    Name: "",
  },
  compareplanogramList: [],
  
  specificplanodata: [],
  recentmaxtendata:[],
  allplanogramforcopy:[],
  response:"",
};
const getters = {
  getallPlanogramforcopy() {
    return state.allplanogramforcopy;
  },
  getPlanogramsimplifiedData() {
    return state.normalizedplanogramdata;
  },

  // for brief step 2
  getPlanogramSpecificData(state) {
    return state.specificplanodata;
  },

  //max 10 recent data
  getPlanogramRecentData(state) {
    return state.recentmaxtendata;
  },

  getplanogram(state) {
    return state.planograms;
  },
  getmultiplyer(state) {
    return state.multiplyer;
  },
  getplanogramdata(state) {
    return state.planogram;
  },
  getcompareplanoList(state) {
    return state.compareplanogramList;
    // var data = [
    //   {
    //     product:{
    //       id:1,
    //       product_name : 'Nest Hub Max',
    //       blob_urls :{
    //         products:[
    //             {
    //               url:'https://th.bing.com/th/id/OIP.sEs6i6sVv7fpzedxWFejBAHaEK?pid=ImgDet&rs=1'
    //             }
    //           ]
    //         }
    //     },
    //     result:'Added'
    //   },
    //   {
    //     product:{
    //       id:2,
    //       product_name : 'Nest Cam',
    //       blob_urls :{
    //         products:[
    //             {
    //               url:'https://th.bing.com/th/id/OIP.sEs6i6sVv7fpzedxWFejBAHaEK?pid=ImgDet&rs=1'
    //             }
    //           ]
    //         }
    //     },
    //     result:'Removed'
    //   },
    //   {
    //     product:{
    //       id:3,
    //       product_name : 'Pixel Buds Pro',
    //       blob_urls :{
    //         products:[
    //             {
    //               url:'https://th.bing.com/th/id/OIP.sEs6i6sVv7fpzedxWFejBAHaEK?pid=ImgDet&rs=1'
    //             }
    //           ]
    //         }
    //     },
    //     result:'Updated'
    //   },
    //   {
    //     product:{
    //       id:4,
    //       product_name : 'Fitbit Charge 5',
    //       blob_urls :{
    //         products:[
    //             {
    //               url:'https://th.bing.com/th/id/OIP.sEs6i6sVv7fpzedxWFejBAHaEK?pid=ImgDet&rs=1'
    //             }
    //           ]
    //         }
    //     },
    //     result:'Added'
    //   }
    // ]
    // return data;
  },

  getResponse(state) {
    return state.response;
  },
};

const mutations = {
  setmultiplyer(state, data) {
    state.multiplyer = data;
  },
  setplanograms(state, data) {
    data.data.sort((a, b) => {
      return b.id - a.id;
    });
    state.planograms = data.data;
  },
  setplanogram(state, data) {
    state.planogram = data.data;
  },
  setcompareplanogram(state, data) {
    state.compareplanogramList = data.data;
  },
  setplanogramsimplifiedData(state, data) {
    state.normalizedplanogramdata = data.data;
  },

  // for brief step 2
  setspecificplanograms(state, data) {
    state.specificplanodata = data.data;
  },

  // max 10 data
  setRecentdata(state, data) {
    state.recentmaxtendata = data.data;
  },

  setcopyplanogramfixretialers(state, data) {
    state.allplanogramforcopy = data.data;
  },

  setResponse(state, data) {
    state.response = data;
  },
};
const actions = {
  updatemultiplyer(context, data) {
    context.commit("setmultiplyer", data);
  },
  createPlanogramData(context, data) {
    return axios
      .post("/api/planogram/create", data)
      .then((response) => {
        return response.data;
        // context.commit("setplanograms", response.data);
      })
      .catch((error) => {
        // console.log("errorr");
        context.commit("setResponse", { data: error.response });
        // return [];
        // context.commit("setResponse", { data: [] });
      });
  },
  getallPlanogram(context) {
    return axios
      .post("/api/planogram/getAll")
      .then((response) => {
        // return response.data
        context.commit("setplanograms", response.data);
      })
      .catch((error) => {
        // console.log("errorr");
        // return []
        context.commit("setplanograms", []);
      });
  },
  getallPlanogramitems(context, id) {
    return axios
      .post(`/api/planogram/${id}/getallitem`)
      .then((response) => {
        // return response.data
        return response.data.data;
      })
      .catch((error) => {
        // console.log("error");
        // return []
        return [];
      });
  },
  getPlanograms(context, id) {
    return axios
      .post(`/api/planogram/${id}/getdata`)
      .then((response) => {
        context.commit("setplanogram", response.data);
      })
      .catch((error) => {
        // console.log("errorr");
        // return []
        return [];
      });
  },
  getPlanogramsimplifiedData(context, id) {
    return axios
      .post(`/api/planogram/${id}/get-planogramdata`)
      .then((response) => {
        context.commit("setplanogramsimplifiedData", response.data);
      })
      .catch((error) => {
        // console.log("errorr");
        // return []
        return [];
      });
  },
  updatename(context, data) {
    return axios
      .post(`/api/planogram/${data.id}/updatedata`, data)
      .then((response) => {
        context.commit("setplanogram", response.data);
      })
      .catch((error) => {
        // console.log("errorr");
        // return []
        return [];
      });
  },
  savedraft(context, data) {
    return axios
      .post(`/api/planogram/${data.id}/draft`, data)
      .then((response) => {
        context.commit("setplanogram", response.data);
      })
      .catch((error) => {
        // console.log("errorr");
        // return []
        return [];
      });
  },
  getcentrifugetoken(context, data) {
    return axios.post(`/api/planogram/${data}/getcentrifugetoken`);
  },
  deleteplanogram(context, data) {
    return axios.post(`/api/planogram/${data}/deleteplanogram`);
  },
  compareplanogram(context, data) {
    // console.log("skdjjvbskjvb");
    // console.log(data);
    return axios
      .post(`/api/planogram/compare-planogram`, data)
      .then((response) => {
        context.commit("setcompareplanogram", response.data);
      })
      .catch((error) => {
        // console.log("errorr");
        // return []
        return [];
      });
  },

  getAllspecificplanogramdata(context, data) {
    return axios
      .post("/api/planogram/get-all-specific-data", data)
      .then((response) => {
        // return response.data
        context.commit("setspecificplanograms", response.data);
      })
      .catch((error) => {
        // console.log("errorr");
        // return []
        context.commit("setplanograms", []);
      });
  },
  Recentsplanogram(context) {
    return axios
      .post("/api/planogram/get-all-recents")
      .then((response) => {
        // return response.data
        context.commit("setRecentdata", response.data);
      })
      .catch((error) => {
        // console.log("errorr");
        // return []
        context.commit("setplanograms", []);
      });
  },
  getallFixRetialersplanogram(context, data) {
    // console.log("skdjjvbskjvb");
    // console.log(data);
    return axios
      .post(`/api/planogram/get-all-fixture-retailers-plano`, data)
      .then((response) => {
        context.commit("setcopyplanogramfixretialers", response.data);
      })
      .catch((error) => {
        // console.log("errorr");
        // return []
        return [];
      });
  },
  copyPlanogramData(context, data) {
    return axios
      .post("/api/planogram/duplicate-planogram", data)
      .then((response) => {
        return response.data;
        // context.commit("setplanograms", response.data);
      })
      .catch((error) => {
        // console.log("errorr");
        context.commit("setResponse", { data: error.response });
        // return [];
        // context.commit("setResponse", { data: [] });
      });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
