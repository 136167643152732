<template>
  <div id="app" :class="computeanim">
    <div v-if="loading && showborder != false" class="loadingBarPrimary"></div>
    <!-- <div v-if="loading" class="loadingcontainer"></div> -->
    <router-view @click="moveUp"  />
    <ScreenAlert  />
    <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar"
      md-persistent>
      <span>{{ message }}</span>
      <md-button class="md-primary" v-if="url_text != null" @click="openLink">{{
        url_text
      }}</md-button>
      <md-button class="md-primary text-white" @click="showSnackbar = false">Ok</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import ScreenAlert from "./smallScreenAlert.vue"
import axios from 'axios';
import { mapActions } from "vuex";
export default {
  name: "app",
  components:{
      ScreenAlert
  },
  data() {
    return {
      message: "",
      url: "",
      url_text: null,
      showSnackbar: false,
      position: "center",
      duration: 5000,
      isInfinity: false,
      loading: false,  
    };
  },
  methods: {
    openLink() {
      this.$router.push({ path: this.url });
    },
    moveUp() {
      // console.log("moving");
      window.scrollTo(0, 0);
    },
    setloading() {
      axios.interceptors.request.use(
        config => {
          // Start the loading state
          this.loading = true
          // console.log(this.loading)

          return config;
        },
        error => {
          setTimeout(() => {
            this.loading = false
          }, 1000);

          // console.log(this.loading)
          return Promise.reject(error);
        }
      );

      // Response Interceptor
      axios.interceptors.response.use(
        response => {
          setTimeout(() => {
            this.loading = false
          }, 1000);
          // console.log(this.loading)
          return response;
        },
        error => {

          this.loading = true
          // console.log(this.loading)
          return Promise.reject(error);
        }
      );
    },
    ...mapActions("user", ["loadProfileInformation"]),
  },
  computed: {
    computeanim() {
      return this.showborder != false ? this.loading ? 'borderTopAnimation relative' : 'borderTopnonanim' : ''
    },
    showborder() {
      return this.$route.meta.bordergone;
    },
    isIdle() {
      if (this.$store.state.idleVue.isIdle) {
        this.$router.push('./logout');
      }
    },
  },
  mounted() {
    this.$emitter.on("message", (e) => {
      this.message = e;
      this.showSnackbar = true;
    });
    this.$emitter.on("showerror", (e) => {
      this.message = e;
      this.showSnackbar = true;
    });
    this.$emitter.on("showsuccess", (e) => {
      this.message = e;
      this.showSnackbar = true;
    });
    this.loadProfileInformation();
    this.setloading()
  },
};
</script>
<style lang="scss">
.text-white {
  .md-button-content {
    color: #fff;
  }
}

.borderTopAnimation {
  // border-top: 5px solid #4285f4;
  border-top: 5px solid white;
  // animation: animationframe 1s forwards;
  // border-top: 0 solid rgb(66, 133, 244);
  // animation: borderTopWidthAnimation 1s forwards;
}

.borderTopnonanim {
  // border-top: 5px solid #4285f4;
  border-top: 5px solid #4285f4;
  // animation: animationframe 1s forwards;
  // border-top: 0 solid rgb(66, 133, 244);
  // animation: borderTopWidthAnimation 1s forwards;
}

@keyframes primaryLoad {
  0% {
    left: -10%;
    width: 20%;
  }

  60% {
    left: 60%;
    width: 40%;
  }

  100% {
    left: 100%;
    width: 0%;
  }
}

.loadingBarPrimary {
  position: fixed;
  top: 0;
  left: 0;
  height: 5px;
  background: linear-gradient(to right, rgb(66, 133, 244), rgba(66, 133, 244, 0.6));
  animation: primaryLoad 2.5s infinite;
}

.loadingcontainer {
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 999999;
}
</style>
