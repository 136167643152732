import Vue from "vue";
import axios from "axios";

const state = {
  prototypes: [],
  prototype_info: {},
  response: {},
  fixtures: [],
  pos: [],
  fixture_pos_list: [],
  checkresponse:"",
};
const getters = {
  getPrototypes(state) {
    return state.prototypes;
  },
  getPrototypeInfo(state) {
    return state.prototype_info;
  },
  getFixtures(state) {
    return state.fixtures;
  },
  getPos(state) {
    return state.pos;
  },
  getResponse(state) {
    return state.response;
  },
  getcheckresponse(state) {
    return state.checkresponse;
  },
  getFixturePOSList(state) {
    return state.fixture_pos_list;
  },
};

const mutations = {
  setPrototypes(state, data) {
    state.prototypes = data.data;
  },
  setPrototypeInfo(state, data) {
    state.prototype_info = data;
  },
  setFixtures(state, data) {
    state.fixtures = data.data;
  },
  setPos(state, data) {
    state.pos = data.data;
  },
  setResponse(state, data) {
    state.response = data;
  },
  setcheckresponse(state, data) {
    state.checkresponse = data.data;
  },
  setFixture_pos_list(state, data) {
    // console.log('data.data')
    // console.log(data.data)
    state.fixture_pos_list = data.data;
  },
};
const actions = {
  storePrototype(context, data) {
    return axios
      .post("/api/prototype/create", data)
      .then((response) => {
        context.commit("setResponse", { data: response });
      })
      .catch((error) => {
        // context.commit("setResponse", { data: [error] });
        context.commit("setcheckresponse", {
          data: error.response,
        });
      });
  },
  loadFixtures(context) {
    // console.log("in store");
    var data = [
      {
        id: 1,
        fixture_name: "test fixture",
        image: "img/fixture1.png",
      },
      {
        id: 2,
        fixture_name: "test fixture 2",
        image: "img/fixture1.png",
      },
    ];
    context.commit("setFixtures", { data: data });
  },
  // loadPos(context) {
  //     return axios.get("/api/prototype/get-all")
  //         .then((response) => {
  //             context.commit("setPos", { data: response.data })
  //         })
  //         .catch((error) => {
  //             context.commit("setResponse", { data: [error] })
  //         })
  // },
  loadPrototypes(context) {
    return axios
      .get("/api/prototype/get-all")
      .then((response) => {
        context.commit("setPrototypes", { data: response.data });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },
  prototypeInfo(context, id) {
    return axios
      .get("/api/prototype/" + id)
      .then((response) => {
        context.commit("setPrototypeInfo", { data: response.data });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
    context.commit("setPrototypeInfo", { data: data });
  },

  loadPOSListByFixure(context, id) {
    return axios
      .get("/api/pos/fixture-pos/" + id)
      .then((response) => {
        context.commit("setFixture_pos_list", { data: response.data });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
