<template>
  <md-dialog
    :md-active.sync="showDialog"
    v-if="screenWidth < 800"
    :md-close-on-esc="false"
    :md-click-outside-to-close="false"
    class="screenAlert-dialog-box"
  >
    <div class="set-height">
      <div class="px-12 text-gray">
        <div class="flex justify-between my-5 pt-5">
          <div class="font-medium text-2xl">Small Screen Alert</div>
          <!-- <span class="material-icons cursor-pointer" @click="$emit('closed')"
            >close</span
          > -->
        </div>

        <div class="my-5">
          This application is best viewed on desktop. So, Please switch to
          desktop for a better experience.
        </div>
      </div>
      <div class="color-border pt-2">
        <span class="r"></span>
        <span class="y"></span>
        <span class="g"></span>
        <span class="b"></span>
      </div>
    </div>
  </md-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      screenWidth: null,
       showDialog: false,
    };
  },

  mounted() {
    this.showDialog = true;
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", this.screenheightcheck);
  },
  watch: {
    showDialog() {
      if (this.showDialog == false) {
        this.$emit("closed");
      }
    },
  },
  methods: {
    screenheightcheck() {
      this.screenWidth = window.innerWidth;
      // console.log(this.screenWidth)
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.screenheightcheck);
  },
};
</script>

<style lang="scss">
.screenAlert-dialog-box {
  .md-dialog-container {
    width: 36rem;
    max-height: 100%;
  }
}
@media only screen and (max-width: 650px) {
  .screenAlert-dialog-box {
    .md-dialog-container {
      width: 380px !important;
      max-height: fit-content !important;
      height:fit-content !important;
    }
  }
}
@media only screen and (max-width: 400px) {
  .screenAlert-dialog-box {
    .md-dialog-container {
      width: 80% !important;
      max-height: fit-content !important;
      height:fit-content !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.set-height {
  height: 100%;
  // overflow: scroll;
}
</style>
