<template>
  <div>
    <div
      class="flex justify-between"
      v-for="(item, index) in files"
      :key="index"
    >
      <div class="flex flex-row items-center gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="13.754"
          height="13.754"
          viewBox="0 0 13.754 13.754"
        >
          <defs>
            <clipPath id="clip-path">
              <rect width="13.754" height="13.754" fill="none" />
            </clipPath>
          </defs>
          <g
            id="Repeat_Grid_2"
            data-name="Repeat Grid 2"
            clip-path="url(#clip-path)"
          >
            <g transform="translate(-239 -1532.623)">
              <path
                id="picture_as_pdf_FILL0_wght500_GRAD0_opsz48"
                d="M7.962,11.132h.621V9.74h.805a.6.6,0,0,0,.621-.621V8.314a.6.6,0,0,0-.621-.621H7.962Zm.621-2.013V8.314h.805v.805Zm2.147,2.013h1.409a.611.611,0,0,0,.436-.176.588.588,0,0,0,.185-.444v-2.2a.588.588,0,0,0-.185-.444.611.611,0,0,0-.436-.176H10.729Zm.621-.621v-2.2h.788v2.2Zm2.214.621h.621V9.74h.839V9.119h-.839V8.314h.839V7.693H13.564ZM6.788,15.107a1.176,1.176,0,0,1-1.141-1.141V4.641a1.085,1.085,0,0,1,.344-.805,1.107,1.107,0,0,1,.8-.335h9.326a1.145,1.145,0,0,1,1.141,1.141v9.326a1.107,1.107,0,0,1-.335.8,1.085,1.085,0,0,1-.805.344Zm0-1.141h9.326V4.641H6.788v9.326ZM4.641,17.254A1.145,1.145,0,0,1,3.5,16.113V5.647H4.641V16.113H15.107v1.141ZM6.788,4.641h0v9.326h0V4.641Z"
                transform="translate(235.5 1529.123)"
                fill="#0f9d58"
              />
            </g>
          </g>
        </svg>

        <a download :href="item.url" v-if="is_select == 'false'" class="flex">
          <!-- <span class="text-base"> file {{ index + 1 }}</span> -->
          <span class="text-base" v-if="typeof item.upload_name == 'undefined'">
            {{ xssFilter(getNamefromPath(item)) }}</span
          >
          <span class="text-base" v-else>{{
            xssFilter(item.upload_name)
          }}</span>

          <!-- <img :src="item" style='height:25px;margin-left:10px' /> -->
        </a>

        <a download :href="item.url" v-else>
          <!-- file {{ index + 1 }}  -->
          <span class="text-lg">
            {{ xssFilter(item.upload_name) }} - {{ item.type.toString() }}</span
          >
        </a>
      </div>
      <div>
        <span
          class="material-icons text-lg cursor-pointer"
          @click="removeImage(index, item.upload_id)"
          >cancel</span
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "app",
  props: {
    files: {
      type: Array,
    },
    is_select: {
      type: String,
      default: "false",
    },
  },
  methods: {
    removeImage(file, id = "") {
      this.$emit("removeFile", file, id);
    },
    xssFilter(string = "") {
      var lt = /</g,
        gt = />/g,
        ap = /'/g,
        ic = /"/g;
      string = string
        .toString()
        .replace(lt, "&lt;")
        .replace(gt, "&gt;")
        .replace(ap, "&#39;")
        .replace(ic, "&#34;");
      return string;
    },
    getNamefromPath(file = "") {
      var f = file.split("/");
      return f.slice(-1);
    },
  },
  mounted() {
    // console.log('my files', this.files);
  },
};
</script>
<style scoped lang="scss">
.icon {
  height: 1.5rem;
}
</style>
