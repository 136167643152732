import axios from 'axios'

const state = {

  briefData: [],
  response: {},
  planogramData: [],
  commonBriefing: [],
  briefFileData: [],
  store_data: [],
  brief_info: {},
  briefresponse: [],
  hideresponse: [],
  headers: [
    {
    value: 'id',
    display: 'File Id',
    sortable: true,
    // is_short_name: true,
  }, 
  {
    value: 'filename',
    display: 'File Name',
    sortable: true,
    // is_short_name: true,
    is_custom:true,
  },
  {
    value: 'file_type',
    display: 'File type',
    sortable: true,
    // is_short_name: true,
    is_custom:true,
  },
  ],

  Hideheaders: [
    {
    value: 'id',
    display: 'File Id',
    sortable: true,
  }, 
  {
    value: 'filename',
    display: 'File Name',
    sortable: true,
    is_custom:true,
  },
  {
    value: 'file_type',
    display: 'File type',
    sortable: true,
    is_custom:true,
  },
  ],
  checkresponse:"",

}
const getters = {
  getHeaders(state) {
    return state.headers;
  },
  gethideHeaders(state) {
    return state.Hideheaders;
  },
  getBriefFileData(state) {
    return state.briefFileData.data
  },
  gethideBrief(state) {
    return state.briefresponse.data;
  },
  gethideData(state) {
    return state.hideresponse.data;
  },
  getBriefData(state) {
    return state.briefData.data
  },
  getplanogramData(state) {
    return state.planogramData.data
  },
  getResponse(state) {
    return state.response
  },
  getcheckresponse(state) {
    return state.checkresponse;
  },
  getCommonBriefing(state) {
    return state.commonBriefing.data
  },
  getStoreData(state) {
    return state.store_data.data
  },
  getBriefInfo(state) {
    return state.brief_info
  }
}
const mutations = {
  setBriefFileData(state, data) {
    state.briefFileData = data
  },
  sethideBrief(state, data) {
    state.briefresponse = data;
  },
  sethideData(state, data) {
    state.hideresponse = data;
  },
  setBriefData(state, data) {
    state.briefData = data
  },
  setplanogramData(state, data) {
    state.planogramData = data
  },
  setResponse(state, data) {
    state.response = data
  },
  setcheckresponse(state, data) {
    state.checkresponse = data.data;
  },
  setCommonBriefingData(state, data) {
    state.commonBriefing = data
  },
  setStoreData(state, data) {
    state.store_data = data
  },
  setBriefInfo(state, data) {
    state.brief_info = data
  }
}
const actions = {
  loadBriefFile(context) {
    return axios
      .get('/api/brief/get-all', {
        responseType: 'json'
      })
      .then(function (response) {
        context.commit('setBriefFileData', { data: response.data.data })
      })
  },
  loadBriefInfo(context, id) {
    return axios
      .get('/api/brief/' + id, {
        responseType: 'json'
      })
      .then(function (response) {
        context.commit('setBriefInfo', { data: response.data.data })
      })
  },
  updatePlanogram(context) {
    var data = [{ 'id': '1', 'update': 'Removed', 'img': '/assets/img/product1.png', 'product_name': 'Nest Hub Max' },
    { 'id': '2', 'update': 'Removed', 'img': '/assets/img/product2.png', 'product_name': 'Nest Cam' },
    { 'id': '3', 'update': 'Added', 'img': '/assets/img/product3.png', 'product_name': 'Pixel Buds Pro' },
    { 'id': '4', 'update': 'Removed', 'img': '/assets/img/product4.png', 'product_name': 'Pixel Buds Pro' }]
    context.commit('setplanogramData', { data: data })
  },
  commonBriefingData(context) {
    return axios
      .get('/api/brief/get-all-briefing-files', {
        responseType: 'json',
      })
      .then(function (response) {
        context.commit('setCommonBriefingData', { data: response.data.data })
      })
  },
  setBriefingData(context, data) {
    return axios
      .post('/api/brief/create', data)
      .then((response) => {
        context.commit('setResponse', { data: response.data })
      })
      .catch((error) => {
        // context.commit('setResponse', { data: [error] })
        context.commit("setcheckresponse", {
          data: error.response,
        });
      })
  },
  setBriefingFiles(context, data) {
    return axios
      .post('/api/brief/upload-briefing-files', data)
      .then((response) => {
        context.commit('setBriefFileData', { data: response.data.data })
      })
      .catch((error) => {
        context.commit('setResponse', { data: [error] })
      })
  },
  getExportStoreData (context, data) {
    return axios
      .post('/api/store/get-store-by-fixture', data)
      .then(function (response) {
        context.commit('setStoreData', { data: response.data.data })
      })
  },
  hideBrief (context, data) {
    return axios
      .post("/api/brief/change-brief-files-status", data)
      .then((response) => {
        context.commit("sethideBrief", { data: response.data });
      })
      .catch((error) => {
        context.commit("sethideBrief", { data: [error] });
      });
  },
  gethideBrief(context) {
    return axios
      .get("/api/brief/get-all-hidden-briefing-files", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("sethideData", { data: response.data });
      });
  }
}
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}