import Vue from "vue";
import axios from "axios";

const state = {
  folder: [],
  folder_info: [],
  response: {},
};
const getters = {
  getFolders(state) {
    return state.folder;
  },
  getFolderInfo(state) {
    return state.folder_info;
  },
  getResponse(state) {
    return state.response;
  },
};

const mutations = {
  setFolders(state, data) {
    state.folder = data.data;
  },
  setFolderInfo(state, data) {
    state.folder_info = data.data;
  },
  setResponse(state, data) {
    state.response = data;
  },
};
const actions = {
  loadFolder(context) {
    return axios
      .get("/api/planogram/folders")
      .then((response) => {
        context.commit("setFolders", { data: response.data });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },
  FolderInfo(context, id) {
    return axios
      .get(`/api/planogram/${id}/folders`)
      .then((response) => {
        context.commit("setFolderInfo", { data: response.data });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },
  createFolder(context, data) {
    // console.log("payload data", data);
    return axios
      .post("/api/planogram/create/folders", data)
      .then((response) => {
        context.commit("setResponse", {
          data: response.data,
        });
      })
      .catch((error) => {
        context.commit("setResponse", {
          data: [error],
        });
      });
  },

  deleteFolder(context, id) {
    axios
      .get(`/api/planogram/folders/${id}/delete`, {
        responseType: "json",
      })
      .then(function (response) {
        axios
          .get("/api/planogram/folders", {
            responseType: "json",
          })
          .then(function (response) {
            context.commit("setFolders", {
              data: response.data.data,
            });
          });
        context.commit("setResponse", {
          data: response,
        });
      });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
