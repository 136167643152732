import Vue from "vue";
import axios from "axios";

const state = {
    vendor_header: [{
        value: 'vendor_id',
        display: 'Vendor ID',
        sortable: true
    }, {
        value: 'vendor_name',
        display: 'Vendor Name',
        sortable: true
    }, {
        value: 'main_vendor',
        display: 'Vendor Type',
        sortable: true
    }, {
        value: 'vendor_domain',
        display: 'Vendor Domain',
        sortable: true
    }, {
        value: 'address',
        display: 'Address',
        sortable: true
    }, {
        value: 'country',
        display: 'Country',
        sortable: true
    }, {
        value: 'users',
        display: 'Users',
        sortable: true
    }, {
        value: 'status',
        display: 'Status',
        sortable: true
    }],
    vendors: [],
    vendor_info: {},
    response: {},
    vendor_type: [{

    }],
    checkresponse:"",
}
const getters = {
    getHeaders(state) {
        return state.vendor_header;
    },
    getVendors(state) {
        var newdata = state.vendors.map((arr) => {
            arr.vendor_id = '#' + arr.vendor_id.toString().padStart(4, '0')
            arr.main_vendor = arr.vendor_type != 'Sub Vendor' ? 'Main Vendor' : 'Sub Vendor'
            return arr
        })
        return newdata
    },
    getVendorInfo(state) {
        var arr_main = state.vendor_info
        if (typeof arr_main.vendor_user_list != 'undefined') {
            var newdata = arr_main.vendor_user_list.map((arr) => {
                arr.feature_access_string = arr.feature_access.toString()
                return arr
            })
            arr_main.vendor_user_list_data = newdata
        }
        return arr_main
    },
    getResponse(state) {
        return state.response
    },
    getcheckresponse(state) {
        return state.checkresponse;
      },
}

const mutations = {
    setVendors(state, data) {
        state.vendors = data.data
    },
    setVendorInfo(state, data) {
        state.vendor_info = data.data
    },
    setResponse(state, data) {
        state.response = data
    },
    setcheckresponse(state, data) {
        state.checkresponse = data.data;
      },
}
const actions = {
    loadVendors(context) {
        // console.log('loading vendor');
        return axios
            .get("/api/vendor/getAll", {
                responseType: "json",
            })
            .then(function (response) {
                context.commit("setVendors", {
                    data: response.data.data
                });
            });

    },
    vendorInfo(context, id) {
        return axios
            .get(`/api/vendor/${id}`, {
                responseType: "json",
            })
            .then(function (response) {
                // console.log("heeeee")
                // console.log(response.data.data)
                context.commit("setVendorInfo", {
                    data: response.data.data
                });
            })
            .catch((error) => {
                context.commit("setVendorInfo", {
                    data: []
                });
            });




    },
    storeVendor(context, data) {
        return axios
            .post("/api/vendor/create", data)
            .then((response) => {

                context.commit("setResponse", {
                    data: response.data
                });
            })
            .catch((error) => {
                // context.commit("setResponse", {
                //     data: [error]
                // });
                context.commit("setcheckresponse", {
                    data: error.response,
                  });
                
            });
    },
};
export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
};