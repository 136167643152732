import axios from "axios";

const state = {
  products: [],
  hiddenProducts: [],
  inv_products: [],
  product_name: null,
  inv_product_name: null,
  response: "",
};
const getters = {
  getProducts(state) {
    var a = state.products;
    a = a.filter((x) => {
      if (state.category != null) {
        if (x.category == state.category) {
          return true;
        }
      } else if (state.product_name != null) {
        if (
          x.product_name
            .toLowerCase()
            .trim()
            .lastIndexOf(state.product_name.toLowerCase().trim(), 0) === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
    return a;
  },
  getHiddenProducts(state) {
    var a = state.hiddenProducts;
    a = a.filter((x) => {
      if (state.category != null) {
        if (x.category == state.category) {
          return true;
        }
      } else if (state.product_name != null) {
        if (
          x.product_name
            .toLowerCase()
            .trim()
            .lastIndexOf(state.product_name.toLowerCase().trim(), 0) === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
    return a;
  },
  getInvProducts(state) {
    var a = state.inv_products;
    a = a.filter((x) => {
      if (state.category != null) {
        if (x.category == state.category) {
          return true;
        }
      } else if (state.inv_product_name != null) {
        // console.log("current");
        if (
          x.product_name
            .toLowerCase()
            .trim()
            .lastIndexOf(state.inv_product_name.toLowerCase().trim(), 0) === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
    return a;
  },
  getProInfo(state) {
    return state.product_info;
  },
  getStatus(state) {},
  getResponse(state) {
    return state.response;
  },
};

const mutations = {
  setproduct(state, data) {
    state.products = data.data;
  },
  setHiddenProduct(state, data) {
    state.hiddenProducts = data.data;
  },
  setInvProduct(state, data) {
    state.inv_products = data.data;
  },
  setCategory(state, data) {
    state.product_name = null;
    state.category = data;
  },
  setProductName(state, data) {
    state.category = null;
    state.product_name = data;
  },
  setInvProductName(state, data) {
    state.category = null;
    state.inv_product_name = data;
    // console.log("setting product", data);
  },
  setResponse(state, data) {
    state.response = data;
  },
};
const actions = {
  setCategory(context, data) {
    context.commit("setCategory", data);
  },
  setProductName(context, data) {
    context.commit("setProductName", data);
  },
  setInvProductName(context, data) {
    context.commit("setInvProductName", data);
  },
  assignCategory(context, data) {
    return axios
      .post("/api/warehouse/add-product-to-warehouse", data)
      .then((response) => {
        context.commit("setResponse", {
          data: response.data,
        });
      })
      .catch((error) => {
        context.commit("setResponse", {
          data: [error],
        });
      });
  },
  hideProducts(context, data) {
    return axios
      .post("/api/warehouse/hide-unhide-warehouse-product", data)
      .then((response) => {
        context.commit("setResponse", {
          data: response.data,
        });
      })
      .catch((error) => {
        context.commit("setResponse", {
          data: [error],
        });
      });
  },
  loadHiddenProducts(context) {
    return axios
      .get("/api/warehouse/get-hidden-wp-list", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setHiddenProduct", {
          data: response.data.data,
        });
      });
    //context.commit("setproduct", { data: products });
  },
  loadproducts(context) {
    return axios
      .get("/api/warehouse/get-warehouse-products", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setproduct", {
          data: response.data.data,
        });
      });
    //context.commit("setproduct", { data: products });
  },
  loadInvProducts(context) {
    // var products = [{
    //     'id': 1,
    //     'product_code': '46328438',
    //     'warehouse': 'Boostudio',
    //     'product_name': 'Pixel Buds Pro',
    //     'category': 'Nest',
    //     'status': 'Deployed in market',
    //     'stock': '200',
    //     'product_image': 'product1.png'
    // }, {
    //     'id': 2,
    //     'product_code': '46328438',
    //     'warehouse': 'Boostudio',
    //     'product_name': 'Pixel Buds test',
    //     'category': 'Pixel',
    //     'status': 'Deployed in market',
    //     'stock': '3',
    //     'product_image': 'product2.png'
    // }, {
    //     'id': 3,
    //     'product_code': '46328438',
    //     'warehouse': 'Boostudio',
    //     'product_name': 'Google retail product',
    //     'category': 'Nest',
    //     'status': 'Deployed in market',
    //     'stock': '20',
    //     'product_image': 'product3.png'
    // }, {
    //     'id': 4,
    //     'product_code': '46328438',
    //     'warehouse': 'Boostudio',
    //     'product_name': 'Pixel Buds test',
    //     'category': 'Pixel',
    //     'status': 'Deployed in market',
    //     'stock': '2',
    //     'product_image': 'product4.png'
    // }, {
    //     'id': 5,
    //     'product_code': '46328438',
    //     'warehouse': 'Boostudio',
    //     'product_name': 'Pixel Buds test',
    //     'category': 'Nest',
    //     'status': 'Deployed in market',
    //     'stock': '10',
    //     'product_image': 'product5.png'
    // }, {
    //     'id': 6,
    //     'product_code': '46328438',
    //     'warehouse': 'Boostudio',
    //     'product_name': 'Pixel Buds test',
    //     'category': 'Pixel',
    //     'status': 'Deployed in market',
    //     'stock': '0',
    //     'product_image': 'product6.png'
    // }, {
    //     'id': 7,
    //     'product_code': '46328438',
    //     'warehouse': 'Boostudio',
    //     'product_name': 'Pixel Buds test',
    //     'category': 'Pixel',
    //     'status': 'Deployed in market',
    //     'stock': '100',
    //     'product_image': 'product1.png'
    // }, {
    //     'id': 8,
    //     'product_code': '46328438',
    //     'warehouse': 'Boostudio',
    //     'product_name': 'Pixel Buds test',
    //     'category': 'Pixel',
    //     'status': 'Deployed in market',
    //     'stock': '10',
    //     'product_image': 'product2.png'
    // }, {
    //     'id': 9,
    //     'product_code': '46328438',
    //     'warehouse': 'Boostudio',
    //     'product_name': 'Pixel Buds test',
    //     'category': 'Pixel',
    //     'status': 'Deployed in market',
    //     'stock': '0',
    //     'product_image': 'product3.png'
    // }, {
    //     'id': 10,
    //     'product_code': '46328438',
    //     'warehouse': 'Boostudio',
    //     'product_name': 'Pixel Buds test',
    //     'category': 'Pixel',
    //     'status': 'Deployed in market',
    //     'stock': '1',
    //     'product_image': 'product4.png'
    // }, {
    //     'id': 11,
    //     'product_code': '46328438',
    //     'warehouse': 'Boostudio',
    //     'product_name': 'Pixel Buds test',
    //     'category': 'Pixel',
    //     'status': 'Deployed in market',
    //     'stock': '10',
    //     'product_image': 'product5.png'
    // }, {
    //     'id': 12,
    //     'product_code': '46328438',
    //     'warehouse': 'Boostudio',
    //     'product_name': 'Pixel Buds test',
    //     'category': 'Nest',
    //     'status': 'Deployed in market',
    //     'stock': '435',
    //     'product_image': 'product6.png'
    // }, {
    //     'id': 13,
    //     'product_code': '46328438',
    //     'warehouse': 'Boostudio',
    //     'product_name': 'Pixel Buds test',
    //     'category': 'Pixel',
    //     'status': 'Deployed in market',
    //     'stock': '1022',
    //     'product_image': 'product6.png'
    // }];
    return axios
      .get("/api/warehouse/get-remote-warehouse-products", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setInvProduct", {
          data: response.data.data,
        });
      });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
