import Vue from "vue";
import Vuex, { Store } from "vuex";
import user from "./store/user";
import product from "./store/product";
import faqs from "./store/faqs";
import fixtures from "./store/fixtures";
import pos from "./store/pos";
import warehouse from "./store/warehouse";
import shipment from "./store/shipment";
import vendor from "./store/vendor";
import prototype from "./store/prototype";
import brief from "./store/brief";
import storelist from "./store/storelist";
import planogram from "./store/planogram";
import marketoverview from "./store/marketoverview";
import folder from "./store/folder";
Vue.use(Vuex);

export default new Store({
  modules: {
    user,
    product,
    faqs,
    fixtures,
    pos,
    warehouse,
    vendor,
    shipment,
    prototype,
    brief,
    storelist,
    planogram,
    marketoverview,
    folder,
  },
  strict: process.env.NODE_ENV !== "production",
});
