import axios from "axios";

const state = {
  headers: [
    // {
    //   value: "id",
    //   display: "Store ID",
    //   className: "",
    //   type: "text",
    //   icon: "",
    //   sortable: true,
    // },
    {
      value: "ice_id",
      display: "ICE ID",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "gis_id",
      display: "GIS ID",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "partner_id",
      display: "Partner ID",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "retailer",
      display: "Retailer",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "store_name",
      display: "Store Name",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "address",
      display: "Address",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "suburb",
      display: "Suburb",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "state",
      display: "State",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "postcode",
      display: "Postcode",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "country",
      display: "Country",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "fixtures",
      display: "Fixtures",
      className: "",
      type: "text",
      icon: "",
      sortable: false,
      is_custom:true,
    },
    // {
    //   value: "tier",
    //   display: "Tier",
    //   className: "",
    //   type: "text",
    //   icon: "",
    //   sortable: true,
    // },
    {
      display: "Store status",
      value: "store_status",
      type: "text",
      icon: "",
      sortable: true,
      is_same_class_value: true,
      class_separator: "-",
      is_custom: true,
    },
  ],
  storeInfo: [],
  storelistData: [],
  productStore: [],
  retailerData: [],
};
const getters = {
  getStorelistData(state) {
    var newdata = state.storelistData;
    // .map((arr) => {
    //   arr.store_status =
    //     arr.storestatus == null
    //       ? "Store Closed"
    //       : arr.storestatus.toLowerCase() == "active"
    //       ? "Store Open"
    //       : arr.storestatus.toLowerCase() == "closed"
    //       ? "Store Closed"
    //       : "Store Refurb";
    //   return arr;
    // });
    return newdata;
  },
  getStoreInfo(state) {
    return state.storeInfo.data;
  },
  getProductStore(state) {
    return state.productStore.data;
  },
  getHeaders(state) {
    return state.headers;
  },

  getRetailerData(state) {
    // console.log("tesin in retailre dta");
    // console.log(state.retailerData);
    return state.retailerData;
  },
};

const mutations = {
  setStorelistData(state, data) {
    state.storelistData = data.data;
  },
  setRetailerData(state, data) {
    // console.log("testing data studdda");
    // console.log(data);
    state.retailerData = data.data;
  },
  setProductStore(state, data) {
    state.productStore = data;
  },
  setStoreInfo(state, data) {
    state.storeInfo = data;
  },
};
const actions = {
  loadStorelistData(context) {
    return axios
      .get("/api/store/get-all", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setStorelistData", {
          data: response.data.data,
        });
      });
  },

  loadRetailerData(context) {
    return axios
      .get("/api/store/retailers", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setRetailerData", {
          data: response.data.data,
        });
      });
  },

  loadStoreInfo(context, id) {
    return axios
      .get("/api/store/" + id, {
        responseType: "json",
      })
      .then(function (response) {
        // console.log("store info", response);
        context.commit("setStoreInfo", {
          data: response.data.data,
        });
      });
  },
  loadProductStore(context) {
    var data = [];
    //   { 'id': '1', 'date': '2021', 'nest': 'Nest', 'img': '/public/img/product1.png', 'name': 'Nest Hub Max', 'more': 'View more' },
    //   { 'id': '2', 'date': '2021', 'nest': 'Nest', 'img': '/public/img/product2.png', 'name': 'Nest Cam', 'more': 'View more' },
    //   { 'id': '3', 'date': '2021', 'nest': 'Nest', 'img': '/public/img/product3.png', 'name': 'Pixel Buds Pro', 'more': 'View more' },
    //   { 'id': '4', 'date': '2021', 'nest': 'Nest', 'img': '/public/img/product4.png', 'name': 'Fitbit Charge 5', 'more': 'View more' },
    //   { 'id': '5', 'date': '2021', 'nest': 'Nest', 'img': '/public/img/product1.png', 'name': 'Nest Hub Max', 'more': 'View more' },
    //   { 'id': '6', 'date': '2021', 'nest': 'Nest', 'img': '/public/img/product2.png', 'name': 'Nest Cam', 'more': 'View more' },
    //   { 'id': '7', 'date': '2021', 'nest': 'Nest', 'img': '/public/img/product3.png', 'name': 'Pixel Buds Pro', 'more': 'View more' },
    //   { 'id': '8', 'date': '2021', 'nest': 'Nest', 'img': '/public/img/product4.png', 'name': 'Fitbit Charge 5', 'more': 'View more' },
    // ];
    context.commit("setProductStore", {
      data: data,
    });
  },
  getStoreInfoById(context, payload) {
    return axios
      .post("/api/store/get-store-by-fixture", payload)
      .then((response) => {
        context.commit("setResponse", {
          data: response.data,
        });
      })
      .catch((error) => {
        context.commit("setResponse", {
          data: [error],
        });
      });
  },
  setDialogData(context, data) {
    // console.log("storelist data", data);
    return axios
      .post("/api/update-fixture-db", data)
      .then((response) => {
        context.commit("setResponse", {
          data: response.data,
        });
      })
      .catch((error) => {
        context.commit("setResponse", {
          data: [error],
        });
      });
  },
  setUpdateStore(context, data) {
    // console.log(data, "gsah");
    return axios
      .post("/api/store/update", data)
      .then((response) => {
        context.commit("setResponse", {
          data: response.data,
        });
      })
      .catch((error) => {
        context.commit("setResponse", {
          data: [error],
        });
      });
  },
  updateStore(context, data) {
    // console.log("storelist data", data);
    return axios
      .post("/api/store/create", data)
      .then((response) => {
        context.commit("setResponse", {
          data: response.data,
        });
      })
      .catch((error) => {
        context.commit("setResponse", {
          data: [error],
        });
      });
  },
  addNewStore(context, data) {
    return axios
      .post("/api/store/single-create", data)
      .then((response) => {
        context.commit("setResponse", {
          data: response.data,
        });
      })
      .catch((error) => {
        context.commit("setResponse", {
          data: [error],
        });
      });
  },
  getfixturemapping(context, data) {
    return axios.post("/api/store/maping", data);
  },
  mapFixtureStore(context, data) {
    // console.log("storelist map data", data);
    return axios
      .post("/api/store/map-store-fixture", data)
      .then((response) => {
        context.commit("setResponse", {
          data: response.data,
        });
      })
      .catch((error) => {
        context.commit("setResponse", {
          data: [error],
        });
      });
  },

  AddFixtureTOstorelist(context, data) {
    // console.log("add fixture to storelist", data);
    return axios
      .post("/api/store/mapfixture-to-store", data)
      .then((response) => {
        context.commit("setResponse", {
          data: response.data,
        });
      })
      .catch((error) => {
        context.commit("setResponse", {
          data: [error],
        });
      });
  },

};
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
