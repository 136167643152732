import Vue from "vue";
import axios from "axios";
import moment from "moment";

const state = {
  headers: [
    {
      value: "name",
      display: "Name",
      sortable: true,
      is_short_name: true,
    },
    {
      value: "email",
      display: "Email",
      className: "",
      type: "email",
      icon: "",
      sortable: true,
    },
    {
      value: "account_type",
      display: "Account Type",
      className: "table-name",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "created_at",
      display: "Account Created",
      className: "table-name",
      type: "text",
      icon: "",
      sortable: true,
      is_custom: true,
    },
    // {
    //   value: 'company',
    //   display: 'Company',
    //   className: 'table-name',
    //   type: 'text',
    //   icon: '',
    //   sortable: true
    // },
    // {
    //   value: 'department',
    //   display: 'Department',
    //   className: 'table-name',
    //   type: 'text',
    //   icon: '',
    //   sortable: true
    // },
    {
      value: "region_access",
      display: "Region access",
      className: "table-name",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "feature_access_string",
      display: "Feature access",
      className: "table-name",
      type: "text",
      icon: "",
      sortable: false,
      is_custom: true,
    },
  ],
  approval_headers: [
    {
      value: "name",
      display: "Name",
      sortable: true,
      is_short_name: true,
    },
    {
      value: "email",
      display: "Email",
      className: "",
      type: "email",
      icon: "",
      sortable: true,
    },
    {
      value: "account_type",
      display: "Account Type",
      className: "table-name",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      // value: 'requested_at',
      value: "created_at",
      display: "Account requested on",
      className: "table-name",
      type: "text",
      icon: "",
      sortable: true,
      is_custom: true,
    },
    {
      value: "company",
      display: "Company",
      className: "table-name",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "department",
      display: "Department",
      className: "table-name",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "region_access",
      display: "Region access",
      className: "table-name",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "feature_access",
      display: "Feature access",
      className: "table-name",
      type: "text",
      icon: "",
      sortable: false,
    },
  ],
  user: {},
  approvals: {},
  loggedin: false,
  response: {},
  accountTypes: {},
  tentativeUserDetail: null,
  requestUserInfo: null,
  validRequestAccess: null,
  profileInformation: {},
  existingNotification: {},
  userProfileInformation: {},
};
const getters = {
  getProfileInformation(state) {
    return state.profileInformation;
  },
  getUserProfileInformation(state) {
    return state.userProfileInformation;
  },
  getHeaders(state) {
    return state.headers;
  },
  getAprHeaders(state) {
    return state.approval_headers;
  },
  getUsers(state) {
    var newdata = state.user.map((arr) => {
      // arr.created_at = moment(arr.created_at).format('Do MMM YYYY');
      arr.feature_access_string = arr.feature_access.toString();
      return arr;
    });
    state.user = newdata;
    return state.user;
  },
  getApprovals(state) {
    return state.approvals;
  },
  loggedin: (state) => state.loggedin,
  getResponse(state) {
    return state.response;
  },
  getTentativeUserDetail(state) {
    return state.tentativeUserDetail;
  },
  getRequestedUserInfo(state) {
    return state.requestUserInfo;
  },
  getValidRequestAccess(state) {
    return state.validRequestAccess;
  },
  getNotificationContent(state) {
    var content = [
      {
        title: "New account request",
        subject: "Be notified for any new user access requests",
        key: "new_account_request",
        email: false,
        desktop: false,
      },
      {
        title: "Updated store list",
        key: "updated_store_list",
        subject: "Be notified when the store list gets updated",
        email: false,
        desktop: false,
      },
      {
        title: "New product added",
        key: "new_product_added",
        subject: "Be notified when a new product gets updated",
        email: false,
        desktop: false,
      },
      {
        title: "New Planogram",
        key: "new_planogram",
        subject: "Be notified when a new planogram gets added",
        email: false,
        desktop: false,
      },
      {
        title: "New prototyping session",
        key: "new_prototyping_session",
        subject: "Be notified when a prototyping sessions is created",
        email: false,
        desktop: false,
      },
    ];
    return content;
  },
  getExistingNotification(state) {
    return state.existingNotification;
  },
  getAccountTypes(state) {
    return state.accountTypes;
  },
};

const mutations = {
  setProfileInformation(state, data) {
    state.profileInformation = data;
  },
  setAccountTypes(state, data) {
    state.accountTypes = data;
  },
  setUserProfileInformation(state, data) {
    state.userProfileInformation = data;
  },
  setloggedin(state, data) {
    state.loggedin = data;
  },
  setuserdata(state, data) {
    state.user = data;
  },
  setApprovals(state, data) {
    state.approvals = data;
  },
  setResponse(state, data) {
    state.response = data;
  },
  setTentativeUserDetail(state, data) {
    state.tentativeUserDetail = data.data;
  },
  setRequestedUserInfo(state, data) {
    state.requestUserInfo = data.data;
  },
  setValidRequestAccess(state, data) {
    state.validRequestAccess = data.data;
  },
  setExistingNotification(state, data) {
    state.existingNotification = data;
  },
};
const actions = {
  loadProfile(context) {
    //context.commit('setloggedin', true)
    return false;
    // return axios
    //   .post("/api/user/profile")
    //   .then(response => {
    //     if (response.data.loggedin || response.data.loggein) {
    //       context.commit("setloggedin", true)
    //       context.commit("setuserdata", response.data.userdata)
    //     }
    //   })
    //   .catch(error => {
    //     context.commit("setloggedin", error)
    //   })
  },
  loadAccountTypes(context) {
    return axios
      .post("/api/user/get-account-types")
      .then((response) => {
        context.commit("setAccountTypes", response.data);
      })
      .catch((error) => {
        context.commit("setAccountTypes", null);
      });
  },
  userLogin(context, data) {
    return axios
      .post("/api/login", data)
      .then((response) => {
        context.commit("setProfileInformation", data);
        context.commit("setResponse", response.data);
        context.commit("setloggedin", true);
      })
      .catch((error) => {
        context.commit("setloggedin", false);
      });
  },
  resendOtp(context, data) {
    return axios
      .post("/api/resendOTP", data)
      .then((response) => {
        context.commit("setResponse", response.data);
      })
      .catch((error) => {
        context.commit("setloggedin", false);
      });
  },
  resendVerificationEmail(context, data) {
    return axios
      .post("/api/resend-verification-email", data)
      .then((response) => {
        context.commit("setResponse", response.data);
      })
      .catch((error) => {
        context.commit("setloggedin", false);
      });
  },
  verifyOtp(context, data) {
    return axios
      .post("/api/verifyOTP", data)
      .then((response) => {
        context.commit("setResponse", response.data);
      })
      .catch((error) => {
        context.commit("setloggedin", false);
      });
  },
  loadUsers(context) {
    return axios
      .get("/api/user/list", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setuserdata", response.data.data);
      });
  },
  loadUserProfile(context, id) {
    return axios
      .post("/api/user/getUserInfo", {
        user_id: id,
      })
      .then(function (response) {
        context.commit("setUserProfileInformation", response.data.data);
      });
  },
  loadProfileInformation(context) {
    return axios
      .get("/api/user/profile", {
        responseType: "json",
      })
      .then(function (response) {
        if (response.data.error == false) {
          context.commit("setloggedin", response.data.success);
          context.commit("setProfileInformation", response.data.data);
        } else {
          context.commit("setloggedin", response.data.success);
        }
      });
  },
  loadApprovals(context) {
    return axios
      .get("/api/user/unapproved-list", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setApprovals", response.data.data);
      });
  },
  requestAccess(context, data) {
    return axios
      .post("/api/request-access", data)
      .then((response) => {
        context.commit("setResponse", response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  },
  loadVerifiedEmail(context, token) {
    return axios
      .get(`/api/verify-email/${token}`, {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setTentativeUserDetail", {
          data: response.data,
        });
      });
  },
  storeRequestedUserInfo(context, data) {
    return axios
      .post("/api/request-tentative-information", data)
      .then((response) => {
        context.commit("setResponse", data);
        context.commit("setRequestedUserInfo", {
          data: response.data,
        });
      })
      .catch((error) => {
        //context.commit("setloggedin", false);
      });
  },
  checkValidRequestAccessUrl(context, id) {
    return axios
      .get(`/api/verify-request-access-link/${id}`, {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setValidRequestAccess", {
          data: response.data,
        });
      });
  },
  changeUserStatus(context, data) {
    // console.log("user store status", data);
    return axios
      .post("/api/user/updateSatus", data)
      .then((response) => {
        context.commit("setResponse", {
          data: response.data,
        });
      })
      .catch((error) => {
        //context.commit("setloggedin", false);
      });
  },
  saveprofile(context, data) {
    return axios
      .post("/api/user/saveprofile", data)
      .then((response) => {
        context.commit("setResponse", {
          data: response.data,
        });
      })
      .catch((error) => {
        //context.commit("setloggedin", false);
      });
  },
  adminsaveprofile(context, data) {
    return axios.post("/api/user/adminsaveprofile", data)
      .then(response => {
        context.commit('setResponse', {
          data: response.data
        });
      })
      .catch(error => {
        //context.commit("setloggedin", false);
      });
  },
  approveUnapproveUser(context, data) {
    // console.log("data", data);
    return axios
      .post("/api/user/mark-approved", data)
      .then((response) => {
        context.commit("setResponse", {
          data: response.data,
        });
      })
      .catch((error) => {
        //context.commit("setloggedin", false);
      });
  },
  loadNotification(context) {
    return axios
      .get("/api/user/usernotification-profile")
      .then(function (response) {
        context.commit("setExistingNotification", response.data);
      });
  },
  setNotification(context, data) {
    // console.log("notify sett", data);
    return axios
      .post("/api/user/usernotification/set-configuration", data)
      .then((response) => {
        context.commit("setResponse", {
          data: response.data,
        });
      })
      .catch((error) => {
        //context.commit("setloggedin", false);
      });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
