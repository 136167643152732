import Vue from "vue";
import axios from "axios";

const state = {
  prototypes: [],
  prototype_info: {},
  response: {},
  fixtures: [],
  pos: [],
  market_overview: {},
  product_overview: {},
  fixture_overview: {},
  deployed_fixture: {},
  market_store_overview: {},
  fixture_overview_retailer: {},
  fixture_overview_store: {},

  product_overview_retailer: {},
  product_overview_store: {},
  yearly_fixture_graph: {},
  yearly_product_graph: {},
  yearly_store_graph: {},
  recentGrhActivites:[],
  export_market_store_overview:[],
  fixturepopup: [],
  productpopup: [],
};
const getters = {
  getfixturepopup(state){
    return state.fixturepopup
  },
  getproductpopup(state){
    return state.productpopup
  },
  getrecentGrhActivites(state) {
    return state.recentGrhActivites;
  },
  getMarketOverview(state) {
    return state.market_overview;
  },
  getProductOverview(state) {
    return state.product_overview;
  },
  getFixtureOverview(state) {
    return state.fixture_overview;
  },
  getDeployedFixture(state) {
    return state.deployed_fixture;
  },

  getMarketStoreOverview(state) {
    return state.market_store_overview;
  },
  getexportMarketStoreOverview(state) {
    // console.log('get point')
    // console.log(state.export_market_store_overview)
    return state.export_market_store_overview.data;
  },

  getFixtureOverviewRetailer(state) {
    return state.fixture_overview_retailer;
  },

  getFixtureOverviewStore(state) {
    return state.fixture_overview_store;
  },

  getProductOverviewRetailer(state) {
    // console.log(
    //   "trying to get product overview retailer data and its a getter"
    // );
    return state.product_overview_retailer;
  },

  getProductOverviewStore(state) {
    return state.product_overview_store;
  },

  getYearly_store_graph(state) {
    return state.yearly_store_graph;
  },
  getYearly_product_graph(state) {
    return state.yearly_product_graph;
  },
  getYearly_fixture_graph(state) {
    return state.yearly_fixture_graph;
  },
};

const mutations = {
  setfixturepopup(state, data){
    state.fixturepopup = data.data
  },
  setproductpopup(state, data){
    state.productpopup = data.data
  },
  setMarketOverview(state, data) {
    state.market_overview = data.data;
  },
  setResponse(state, data) {
    state.response = data;
  },

  setProductOverview(state, data) {
    state.product_overview = data.data;
  },

  setFixtureOverview(state, data) {
    state.fixture_overview = data.data;
  },

  setDeployedFixture(state, data) {
    state.deployed_fixture = data.data;
  },

  setMarketStoreOverview(state, data) {
    state.market_store_overview = data.data;
  },
  setexportMarketStoreOverview(state, data) {
    state.export_market_store_overview = data.data;
  },

  setFixtureOverviewRetailer(state, data) {
    state.fixture_overview_retailer = data.data;
  },

  setFixtureOverviewStore(state, data) {
    state.fixture_overview_store = data.data;
  },

  setProductOverviewRetailer(state, data) {
    // console.log(" value is geting seti in the setters");
    state.product_overview_retailer = data.data;

    // console.log(state.product_overview_retailer);
  },

  setProductOverviewStore(state, data) {
    state.product_overview_store = data.data;
  },

  setYearlyFixtureGraph(state, data) {
    state.yearly_fixture_graph = data.data;
  },
  setYearlyProductGraph(state, data) {
    state.yearly_product_graph = data.data;
  },
  setYearlyStoreGraph(state, data) {
    state.yearly_store_graph = data.data;
  },
  setrecentGrhActivites(state, data) {
    state.recentGrhActivites = data.data;
  },
};
const actions = {
  loadMarketOverview(context, date) {
    return axios
      .get(`/api/marketoverview/general-market-overview?filter=${date}`)
      .then((response) => {
        // console.log("this what i m getitng");
        // console.log(response.data.data);
        context.commit("setMarketOverview", { data: response.data.data });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },

  loadFixtureOverview(context, date) {
    return axios
      .get(`/api/marketoverview/general-market-fixture-overview?filter=${date}`)
      .then((response) => {
        // console.log("this what i m getitng");
        // console.log(response.data.data);
        context.commit("setFixtureOverview", { data: response.data.data });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },

  loadProductOverview(context, date) {
    return axios
      .get(`/api/marketoverview/general-market-product-overview?filter=${date}`)
      .then((response) => {
        // console.log("this what i m getitng product overview");
        // console.log(response.data.data);
        context.commit("setProductOverview", { data: response.data.data });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },

  loadDeployedFixture(context, date) {
    return axios
      .get(
        `/api/marketoverview/general-market-deployed-fixtures?filter=${date}`
      )
      .then((response) => {
        // console.log("this what i m getitng product overview");
        // console.log(response.data.data);
        context.commit("setDeployedFixture", { data: response.data.data });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },

  loadMarketStoreOverview(context, date) {
    return axios
      .get(`/api/marketoverview/general-market-store-overview?filter=${date.date}&current_page=${date.current}&limit=${date.perpage}&export=${date.export}`)
      .then((response) => {
        // console.log("this what i m getitng MarketStoreOverview");
        // console.log(response.data.data);
        context.commit("setMarketStoreOverview", { data: response.data.data });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },
  exportMarketStoreOverview(context, date) {
    return axios
      .get(`/api/marketoverview/general-market-store-overview?filter=${date.date}&current_page=${date.current}&limit=${date.perpage}&export=${date.export}`)
      .then((response) => {
        // console.log("this what i m getitng exportMarketStoreOverview");
        // console.log(response);
        context.commit("setexportMarketStoreOverview", { data: response.data });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },

  loadFixtureOverviewRetailer(context, date) {
    return axios
      .get(
        `/api/marketoverview/fixture-market-overview-retailer?filter=${date}`
      )
      .then((response) => {
        // console.log("this what i m getitng product overview");
        // console.log(response.data.data);
        context.commit("setFixtureOverviewRetailer", {
          data: response.data.data,
        });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },

  loadFixtureOverviewStore(context, date) {
    return axios
      .get(`/api/marketoverview/fixture-market-overview-store?filter=${date}`)
      .then((response) => {
        // console.log("this what i m getitng MarketStoreOverview");
        // console.log(response.data.data);
        context.commit("setFixtureOverviewStore", { data: response.data.data });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },

  loadProductOverviewRetailer(context, date) {
    return axios
      .get(
        `/api/marketoverview/product-market-overview-retailer?filter=${date}`
      )
      .then((response) => {
        // console.log("this what i m getitng product overview");
        // console.log(response.data.data);
        context.commit("setProductOverviewRetailer", {
          data: response.data.data,
        });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },

  loadProductOverviewStore(context, date) {
    return axios
      .get(`/api/marketoverview/product-market-overview-store?filter=${date}`)
      .then((response) => {
        // console.log("this what i m getitng MarketStoreOverview");
        // console.log(response.data.data);
        context.commit("setProductOverviewStore", { data: response.data.data });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },

  prototypeInfo(context, id) {
    return axios
      .get("/api/prototype/" + id)
      .then((response) => {
        context.commit("setPrototypeInfo", { data: response.data });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },
  loadYearlyFixtureGraphData(context) {
    return axios
      .get("/api/marketoverview/yearly-fixture-count", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setYearlyFixtureGraph", {
          data: response.data.data,
        });
      });
  },
  loadYearlyStoreGraphData(context) {
    return axios
      .get("/api/marketoverview/yearly-store-count", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setYearlyStoreGraph", {
          data: response.data.data,
        });
      });
  },
  loadYearlyProductGraphData(context) {
    return axios
      .get("/api/marketoverview/yearly-product-count", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setYearlyProductGraph", {
          data: response.data.data,
        });
      });
  },
  loadrecentGrhActivitesData(context) {
    return axios
      .get("/api/recent-grh-activites", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setrecentGrhActivites", {
          data: response.data.data,
        });
      });
  },
  loadFixturePopupData(context, data) {
    // console.log("add fixture to storelist", data);
    return axios
      .post("/api/fixture/fixture-specifics", data)
      .then((response) => {
        context.commit("setfixturepopup", {
          data: response.data,
        });
      })
      .catch((error) => {
        context.commit("setResponse", {
          data: [error],
        });
      });
  },
  loadProductPopupData(context, data) {
    // console.log("add fixture to storelist", data);
    return axios
      .post("/api/product/product-specifics", data)
      .then((response) => {
        context.commit("setproductpopup", {
          data: response.data,
        });
      })
      .catch((error) => {
        context.commit("setResponse", {
          data: [error],
        });
      });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
