import Vue from "vue";
import axios from "axios";

const state = {
  headers: [
    {
      value: "action",
      display: "Asset Link",
      sortable: false,
      is_custom: true,
    },
    {
      value: "pos_id",
      display: "POS ID",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "asset_name",
      display: "POS Title",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "sku",
      display: "SKU",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "fixture_type",
      display: "Fixture Type",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
      is_custom: true,
    },
    {
      value: "products",
      display: "Products",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
      is_custom: true,
    },
    {
      value: "retailers",
      display: "Retailers",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
      is_custom: true,
    },
    // {
    //   value: "asset_name",
    //   display: "Asset Name",
    //   className: "",
    //   type: "text",
    //   icon: "",
    //   sortable: true,
    // },
    {
      value: "width",
      display: "Width",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "height",
      display: "Height",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "material",
      display: "Material",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "asset_type",
      display: "Asset Type",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "asset_category",
      display: "Asset Category",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
      is_same_class_value: true,
    },
    {
      value: "creative_refresh",
      display: "Creative Refresh",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
    {
      value: "launch_month",
      display: "Launch Month",
      className: "",
      type: "text",
      icon: "",
      sortable: true,
    },
  ],
  sku_string: "",
  poslist: [],
  pos_info: {},
  response: "",
  productTypes: [],
  vendorTypes: [],
  creativeRefreshList: [],
  assetCategoryList: [],
  materialList: [],
  assetTypeList: [],
  fixtureTypes: [],
};
const getters = {
  getSkuString(state) {
    return state.sku_string;
  },

  getFixtureTypes(state) {
    return state.fixtureTypes;
  },
  getAssetTypeList(state) {
    return state.assetTypeList;
  },
  getCreativeRefreshList(state) {
    return state.creativeRefreshList;
  },
  getMaterialList(state) {
    return state.materialList;
  },
  getAssetCategoryList(state) {
    return state.assetCategoryList;
  },
  getHeaders(state) {
    return state.headers;
  },
  getPos(state) {
    return state.poslist;
  },
  getPosInfo(state) {
    return state.pos_info;
  },
  getResponse(state) {
    return state.response;
  },
  getProductTypes(state) {
    return state.productTypes;
  },
  getVendorTypes(state) {
    return state.vendorTypes;
  },
};

const mutations = {
  setSkuString(state, data) {
    state.sku_string = data.data;
  },

  setFixtureTypes(state, data) {
    state.fixtureTypes = data.data;
  },

  setAssetTypeList(state, data) {
    state.assetTypeList = data.data;
  },

  setPos(state, data) {
    var months = [
      "",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    // debugger;
    var newdata = data.data.map((arr) => {
      //console.log('its arr', arr);
      arr.is_hover = false;
      arr.is_hover_fixture = false
      arr.is_hover_retailer = false
      arr.product_info = [];
      arr.product_ids = [];
      arr.fixture_info = [];
      arr.fixture_ids = [];
      arr.retailer_ids = [];
      arr.retailer_info = [];

      //product
      if (typeof arr.products != "undefined") {
        arr.product_ids = arr.products.products_data.map((x) => x.id);
        if (arr.products.hover_required) {
          arr.is_hover = true;
          arr.product_info = arr.products.products_data.map(
            (x) => x.productName
          );
        }
        arr.products = arr.products.text;
      }
      arr.product_info = arr.product_info.toString();


      //fixture
      if (typeof arr.fixture_type != "undefined") {
        arr.fixture_ids = arr.fixture_type.products_data.map((x) => x.id);
        if (arr.fixture_type.hover_required) {
          arr.is_hover_fixture = true;
          arr.fixture_info = arr.fixture_type.products_data.map(
            (x) => x.name
          );
        }
        arr.fixture_type = arr.fixture_type.text;
      }
      arr.fixture_info = arr.fixture_info.toString();


      //retailer
      if (typeof arr.retailers != "undefined") {
        arr.retailers = JSON.parse(arr.retailers)
        arr.retailer_ids = arr.retailers//.map((x) => x);
        // if (arr.fixture_type.hover_required) {
        arr.is_hover_retailer = true;
        arr.retailer_info = arr.retailers
        // }
        arr.retailer_type = "Various";
      }
      // arr.retailer_info = arr.retailer_info.toString();

      // arr.act_launch_month = arr.launch_month;
      // arr.launch_month = months[arr.launch_month];
      return arr;
    });
    state.poslist = newdata;
  },

  setMaterialList(state, data) {
    state.materialList = data.data;
  },

  setResponse(state, data) {
    state.response = data;
  },

  setProductTypes(state, data) {
    state.productTypes = data.data;
  },

  setVendorTypes(state, data) {
    state.vendorTypes = data.data;
  },

  setCreativeRefreshList(state, data) {
    state.creativeRefreshList = data.data;
  },

  setAssetCategoryList(state, data) {
    state.assetCategoryList = data.data;
  },

  setPosInfo(state, data) {
    state.pos_info = data.data;
  },
};
const actions = {
  createPos(context, data) {
    return axios
      .post("/api/pos/create", data)
      .then((response) => {
        context.commit("setResponse", { data: response.data });
      })
      .catch((error) => {
        // if (error.response) {
        //   console.log("its error showinggggg");
        //   console.log(error.response.data);
        //   console.log(error.response.status);
        //   console.log(error.response.headers);
        // }
        context.commit("setResponse", { data: error.response });
      });
  },
  createSkuString(context, data) {
    // console.log("sku generation parameter", data);
    return axios
      .post("/api/pos/get-sku-string", data)
      .then((response) => {
        context.commit("setResponse", { data: response.data });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },
  loadPos(context) {
    return axios
      .get("/api/pos/get-all", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setPos", { data: response.data.data });
      });
  },
  getPosInfo(context, id) {
    return axios
      .get("/api/product/product-types-list", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setPosInfo", { data: response.data.data });
      });
  },
  loadMultiplePos(context, data) {
    return axios
      .post("/api/pos/get-selected-pos-list", data)
      .then(function (response) {
        context.commit("setPos", { data: response.data.data });
      });
  },
  loadProductTypes(context) {
    return axios
      .get("/api/product/product-types-list", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setProductTypes", { data: response.data.data });
      });
  },
  loadVendorTypes(context) {
    return axios
      .get("/api/vendor/get-vendor-types", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setVendorTypes", { data: response.data.data });
      });
  },
  loadCreativeRefreshList(context) {
    return axios
      .get("/api/pos/get-creative-refresh-list", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setCreativeRefreshList", { data: response.data.data });
      });
  },
  loadAssetCategoryList(context) {
    return axios
      .get("/api/pos/get-asset-category-list", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setAssetCategoryList", { data: response.data.data });
      });
  },
  loadMaterialList(context) {
    return axios
      .get("/api/pos/get-material-type-list", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setMaterialList", { data: response.data.data });
      });
  },
  loadAssetTypeList(context) {
    return axios
      .get("/api/pos/get-asset-type-list", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setAssetTypeList", { data: response.data.data });
      });
  },
  loadFixtureTypes(context) {
    return axios
      .get("/api/fixture/get-all-fixture-types", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setFixtureTypes", { data: response.data.data });
      });
  },
  deletePos(context, id) {
    return axios
      .post(`/api/pos/${id}/delete`, id)
      .then((response) => {
        context.commit("setResponse", { data: response.data });
      })
      .catch((error) => {
        context.commit("setResponse", { data: [error] });
      });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
