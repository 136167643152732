import Vue from "vue";
import axios from "axios";

const state = {
  fixtures: [],
  fixture_info: [],
  fixtureData: [],
  fixture_all_page: [],
  filter: null,
  prototype_fixture: [],
  checkresponse: "",
  fixtureType: [
    {
      key: "GMFG",
      value: "Google Made Fixture & Google updated",
    },
    {
      key: "GMFP",
      value: "Google Made Fixture, partner updated",
    },
    {
      key: "PMFG",
      value: "Partner Made Fixture & partner updated",
    },
    {
      key: "PMGU",
      value: "Partner Made Fixture & Google Updated",
    },
  ],
  fixture_dropdown_list: [],
};
const getters = {
  getFixtures(state) {
    var a = state.fixtures;
    if (state.filter == null) {
      return a;
    }
    a = a.filter((x) => {
      if (x.type == state.filter) {
        return true;
      } else {
        return false;
      }
    });
    return a;
  },
  getFixtureInfo(state) {
    return state.fixture_info;
  },
  getPrototypeFixture(state) {
    return state.prototype_fixture;
  },
  get_fixture_dropdown_list(state) {
    return state.fixture_dropdown_list;
  },
  getResponse(state) {
    return state.response;
  },
  getcheckresponse(state) {
    return state.checkresponse;
  },
  getfixtureData(state) {
    return state.fixtureData;
  },
  getFixtureType(state) {
    return state.fixtureType;
  },
  getFixtureAllPage(state) {
    return state.fixture_all_page;
  },
};

const mutations = {
  setfixture(state, data) {
    state.fixtures = data.data;
  },
  setFixtureInfo(state, data) {
    state.fixture_info = data.data;
  },
  setResponse(state, data) {
    state.response = data.data;
  },
  setcheckresponse(state, data) {
    state.checkresponse = data.data;
  },
  setfixtureData(state, data) {
    state.fixtureData = data.data;
  },
  setFilter(state, data) {
    state.filter = data;
  },

  setPrototypeFixture(state, data) {
    state.prototype_fixture = data.data;
  },

  set_fixture_dropdown_list(state, data) {
    state.fixture_dropdown_list = data.data;
  },
  set_fixture_getall_page(state, data) {
    state.fixture_all_page = data.data;
  },
};
const actions = {
  updateFilterType(context, filter) {
    context.commit("setFilter", filter);
  },
  loadfixtures(context) {
    return axios
      .get("/api/fixture/getAll", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setfixture", {
          data: response.data.data,
        });
      });
  },
  loadfixturesdropdownlist(context) {
    return axios
      .get("/api/fixture/getAllfixture-dropdown", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("set_fixture_dropdown_list", {
          data: response.data.data,
        });
      });
  },

  loadfixturegetallpage(context) {
    return axios
      .get("/api/fixture/get-all-fixturepage", {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("set_fixture_getall_page", {
          data: response.data.data,
        });
      });
  },
  fixtureinfo(context, id) {
    return axios
      .get(`/api/fixture/${id}`, {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setFixtureInfo", {
          data: response.data,
        });
      });
  },
  loadProtoypeFixtureInfo(context, id) {
    return axios
      .get(`/api/fixture/${id}/prototype-fixture`, {
        responseType: "json",
      })
      .then(function (response) {
        context.commit("setPrototypeFixture", {
          data: response.data,
        });
      });
  },
  createFixture(context, data) {
    return axios
      .post("/api/fixture/create", data)
      .then((response) => {
        context.commit("setResponse", {
          data: response,
        });
      })
      .catch((error) => {
        // console.log("errorr");
        context.commit("setcheckresponse", {
          data: error.response,
        });
      });
  },
  deleteFixture(context, id) {
    return axios.get(`/api/fixture/${id}/delete`, {
      responseType: "json",
    });
  },
  loadfixtureData(context) {
    var data = [
      {
        id: "1",
        img: "/assets/img/fixtures/demo_main.png",
        details: "Connected Home Table 360",
      },
      {
        id: "2",
        img: "/assets/img/fixtures/smart-table.png",
        details: "Smart Connected Home Table",
      },
      {
        id: "3",
        img: "/assets/img/fixtures/smart-table.png",
        details: "Nest In-line display",
      },
      {
        id: "4",
        img: "/assets/img/fixtures/smart-table.png",
        details: "1800 Tall Skinny",
      },
      {
        id: "5",
        img: "/assets/img/fixtures/matel-tower.png",
        details: "CMetal Tower 2.0",
      },
    ];
    context.commit("setfixtureData", {
      data: data,
    });
  },
  printfixture(context, data) {
    return axios.post("/api/fixture/get-pdf/" + data);
  },
  EndofFixture(context, data) {
    return axios
      .post("/api/fixture/active-inactive", data)
      .then((response) => {
        context.commit("setResponse", {
          data: response,
        });
      })
      .catch((error) => {
        // console.log("errorr");
        context.commit("setcheckresponse", {
          data: error.response,
        });
      });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
