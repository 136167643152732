<template>
  <div :class="{ 'filepond-opened': is_open }">
    <div
      class="row inputbox pt-5 upload-parent file-ponder"
      :class="
        is_select == 'true'
          ? 'filepond-with-selection ' + parent_class
          : parent_class
      "
    >
      <label class="block text-lg font-bold text-gray">{{ label }}</label>
      <UploadList
        :files="filledImages"
        :is_select="is_select"
        @removeFile="removeExistingFile"
      />
      <div class="upload_file" @click="checkSize" :class="main_class">
        <file-pond
          :maxFiles="max_files"
          name="filepond"
          @updatefiles="onFileChange"
          :allow-multiple="true"
          credits="false"
          allowImagePreview="false"
          :ref="randostring"
          :label-idle="message"
          :accepted-file-types="extension"
          :server="{
            process: this.onUpload,
            url: '/api/raven',
            revert: '/revert/',
            headers: { 'X-CSRFTOKEN': csrf },
          }"
        />
        <img :src="$ASSET_PATH + 'Icon.png'" />
      </div>
      <!-- //:allow-multiple=this.multiple -->
      <!-- : files="filledImagestest" -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import UploadList from "./upload-list.vue";
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);
export default {
  name: "app",
  components: {
    FilePond,
    UploadList,
  },
  props: {
    label: {
      type: String,
      default: "Image",
    },
    images: {
      type: Array,
    },
    filledImages: {
      type: Array,
    },
    extImg: {
      type: Array,
    },
    name: {
      type: String,
      default: "",
    },
    is_select: {
      type: String,
      default: "false",
    },
    main_class: {
      type: String,
      default: "no-class",
    },
    parent_class: {
      type: String,
      default: "no-class",
    },
    isMultiple: {
      type: Boolean,
      default: true,
    },
    types: {
      type: Array,
    },
    extension: {
      type: String,
      default: "image/jpeg, image/png, application/pdf",
    },
    message: {
      type: String,
      default: "Drag and drop images & files or Browse<br>Max file size 500MB",
    },
    max_files: {
      type: Number,
      default: null,
    },
  },
  data: function () {
    function readCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }
    let r = (Math.random() + 1).toString(36).substring(7);
    var token = readCookie("csrftoken");
    return {
      randostring: "pondid" + r,
      slider: false,
      file_type: "",
      myFiles: [],
      tempfiles: [],
      fileTypes: [],
      csrf: token,
      is_open: false,
    };
  },
  methods: {
    checkSize() {
      if (this.max_files < 1 && this.max_files != null) {
        this.$emitter.emit(
          "message",
          "You have exceed the maximum limit of upload"
        );
        return false;
      }
      // console.log(
      //   this.images.length,
      //   " ",
      //   this.filledImages.length,
      //   " ",
      //   this.max_files
      // );
    },
    test() {
      // console.log("testetse");
    },
    onFileChange(files) {
      // console.log("files", files.length);
      // console.log("myfiles", this.myFiles.length);
      if (files.length < this.myFiles.length) {
        var tempnew = [];
        files.forEach((x) => {
          tempnew.push(x.serverId);
        });
        this.myFiles = tempnew;
        this.$emit(
          "setServerId",
          this.myFiles,
          this.fileTypes,
          this.is_select,
          this.name
        );
        // console.log("my changes", this.myFiles.length);
        var txt = "";
        if (this.myFiles.length > 0) {
          txt = this.myFiles.length + " file uploaded.";
        } else {
          this.is_open = false;
          this.toggleSlider();
        }
        document.querySelector(
          "." + this.main_class + " .file-pond-file-counter"
        ).innerHTML = txt;
      }
    },
    removeExistingFile(file, upload_id = null) {
      // console.log("removing..", upload_id);
      this.$emit("removeFile", file, this.name, upload_id);
    },
    async onUpload(fieldName, file, metadata, load, error, progress, abort) {
      // console.log("uploadingg...");
      // if(this.multiple=='false' && this.myFiles.length>0) {
      //   return false;
      // }
      const formData = new FormData();
      // formData.append("filepond", {});
      formData.append("filepond", file, file.name);
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      this.is_open = true;
      // upload counter added
      document.querySelector(
        "." + this.main_class + " .filepond--list"
      ).style.display = "block";
      this.slider = true;
      var theParent = document.querySelector(
        "." + this.main_class + " .filepond--list-scroller"
      );
      //theParent.style.display ='block';
      if (
        document.querySelector(
          "." + this.main_class + " .file-pond-file-counter"
        ) != null
      ) {
        document.querySelector(
          "." + this.main_class + " .file-pond-file-counter"
        ).innerHTML = "Uploading...";
      } else {
        let main_child = document.createElement("div");
        main_child.setAttribute("class", "file-pond-file-counter-parent");
        let theKid = document.createElement("div");
        theKid.setAttribute("class", "file-pond-file-counter");
        theKid.innerHTML = "Uploading...";
        let arrowChild = document.createElement("span");
        arrowChild.setAttribute("class", "file-pond-file-arrow material-icons");
        arrowChild.innerHTML = "expand_less";
        arrowChild.addEventListener("click", () => {
          this.toggleSlider();
        });
        main_child.appendChild(theKid);
        main_child.appendChild(arrowChild);
        theParent.appendChild(main_child);
      }
      //counter end
      if (this.isMultiple == false) {
        if (this.myFiles.length > 0) {
          // console.log(this.myFiles[0]);
          this.$refs[this.randostring].removeFile(1);
        }
      }
      await axios({
        method: "post",
        url: "/api/raven/process/",
        data: formData,
        cancelToken: source.token,
        onUploadProgress: (e) => {
          // console.log("events", e);
          //  eslint-disable-next-line
          progress(e.lengthComputable, e.loaded, e.total);
        },
        onaddfilestart(file) {
          // console.log("started", file);
        },
      })
        .then((response) => {
          this.is_open = true;
          load(response.data);
          if (this.isMultiple == true) {
            this.myFiles.push(response.data);
          } else {
            this.myFiles = [response.data];
          }

          var theParent = document.querySelector(
            "." + this.main_class + " .filepond--list-scroller"
          );
          //theParent.children('.filepond--list').style.display = 'block';
          // console.log("chk length", this.myFiles.length);
          document.querySelector(
            "." + this.main_class + " .file-pond-file-counter"
          ).innerHTML = this.myFiles.length + " file uploaded.";
          this.is_open = true;
          // if (document.querySelector('.'+this.main_class + ' .file-pond-file-counter')!=null ) {
          //   document.querySelector('.'+this.main_class + ' .file-pond-file-counter').innerHTML=this.myFiles.length + ' Image uploaded.';
          // } else {
          //   let theKid = document.createElement("div");
          //   theKid.setAttribute("class", "file-pond-file-counter");
          //   theKid.innerHTML = this.myFiles.length + ' Image uploaded.';
          //   theParent.appendChild(theKid);
          // }
          // document.querySelector('.'+this.main_class + ' .filepond--list').style.display = 'none';
          if (this.is_select == "true") {
            this.selectBoxCreate();
          } else {
            this.$emit(
              "setServerId",
              this.myFiles,
              this.fileTypes,
              this.is_select,
              this.name
            );
          }
        })
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            // console.log("Request canceled", thrown.message);
          } else {
            // handle error
          }
        });

      // Setup abort interface
      return {
        abort: () => {
          source.cancel("Operation canceled by the user.");
        },
      };
    },
    onDelete(uniqueFileId, load, error) {
      const index = this.myFiles.indexOf(uniqueFileId);
      this.myFiles.splice(index, 1);
      if (index > -1) {
        this.fileTypes.splice(index, 1);
      }
      this.$emit(
        "setServerId",
        this.myFiles,
        this.fileTypes,
        this.is_select,
        this.name
      );
      const formData = new FormData();
      // formData.append("filepond", {});
      formData.append("filepond", uniqueFileId);
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      axios({
        method: "delete",
        url: "/api/raven/revert/",
        data: formData,
        cancelToken: source.token,
      })
        .then((response) => {
          //load(response.data);
          // console.log("remove", response.data);
        })
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            // console.log("Request canceled", thrown.message);
          } else {
            // handle error
          }
        });

      // Setup abort interface
      return {
        abort: () => {
          source.cancel("Operation canceled by the user.");
        },
      };
    },
    toggleSlider() {
      //this.is_open = true;
      let slide = document.querySelector(
        "." + this.main_class + " .filepond--list"
      );
      let icon = document.querySelector(
        "." + this.main_class + " .file-pond-file-arrow"
      );
      this.slider = !this.slider;
      if (this.slider) {
        this.is_open = true;
        slide.style.display = "block";
        icon.innerHTML = "expand_less";
      } else {
        this.is_open = false;
        slide.style.display = "none";
        icon.innerHTML = "expand_more";
      }
    },
    setValues() {
      this.fileTypes = [];
      const highlightedItems = document.querySelectorAll(".file-pond-select");
      highlightedItems.forEach((item) => {
        this.fileTypes.push(item.value);
      });
      this.fileTypes = this.fileTypes.reverse();
      this.$emit(
        "setServerId",
        this.myFiles,
        this.fileTypes,
        this.is_select,
        this.name
      );
    },
    createSelect() {
      var cnt = this.myFiles.length;
      var select = `<div id="app-cover">
  <div id="select-box">
    <input type="checkbox" class='options-view-button options-view-button${cnt}' id="options-view-button">
    <div id="select-button" class="brd">
      <div id="selected-value">
        <span>Select briefs</span>
      </div>
      <div id="chevrons">
        <i class="material-icons">expand_less</i>
        <i class="material-icons">expand_more</i>
      </div>
    </div>
    <div id="options">
      <div class="option">
        <input class="s-c top" type="radio" name="platform${cnt}" value="Installation">
        <input class="s-c bottom" type="radio" name="platform${cnt}" value="Installation">
        <i class="material-icons green">fiber_manual_record</i>
        <span class="label">Installation</span>
        <span class="opt-val">Installation</span>
      </div>
      <div class="option">
        <input class="s-c top" type="radio" name="platform${cnt}" value="Removal">
        <input class="s-c bottom" type="radio" name="platform${cnt}" value="Removal">
        <i class="material-icons red">fiber_manual_record</i>
        <span class="label">Removal</span>
        <span class="opt-val">Removal</span>
      </div>
      <div class="option">
        <input class="s-c top" type="radio" name="platform${cnt}"  value="Update">
        <input class="s-c bottom" type="radio" name="platform${cnt}" value="Update">
        <i class="material-icons yellow">fiber_manual_record</i>
        <span class="label">Update</span>
        <span class="opt-val">Update</span>
      </div>
    </div>
  </div>
</div>`;
      return ""; //select;
    },

    setFileType() {
      var total = this.myFiles.length;
      this.fileTypes = [];
      for (var i = 1; i <= total; i++) {
        var item = document.querySelector(
          "input[name=platform" + i + "]:checked"
        );
        this.fileTypes.push(item.value);
        //document.querySelector(".options-view-button").click();
      }
      this.$emit(
        "setServerId",
        this.myFiles,
        this.fileTypes,
        this.is_select,
        this.name
      );
      // var newelement = document.querySelectorAll('.options-view-button');
      // for (var i = 0; i < newelement.length; i++) {
      //     newelement[i].click();
      // }
      //document.querySelectorAll(".options-view-button").forEach(el=>el.click());
    },
    selectBoxCreate() {
      const allelement = document.querySelectorAll(
        ".filepond-with-selection .filepond--list .filepond--file"
      );
      allelement.forEach((nelement) => {
        if (nelement.querySelector(".file-pond-select") == null) {
          var select = document.createElement("SELECT");
          select.setAttribute("class", "file-pond-select");
          select.setAttribute(":v-model", "fileTypes");
          select.addEventListener("change", () => {
            this.setValues();
          });

          nelement.appendChild(select);
          nelement.insertAdjacentHTML("beforeend", this.createSelect());
          var newelement = document.querySelectorAll("#options .option input");
          for (var i = 0; i < newelement.length; i++) {
            newelement[i].addEventListener("click", (evt) => {
              this.setFileType();
            });
          }
          var opt1 = document.createElement("option");
          var opt2 = document.createElement("option");
          var opt3 = document.createElement("option");
          opt1.setAttribute("value", "installation");
          opt2.setAttribute("value", "removal");
          opt3.setAttribute("value", "update");
          opt1.insertAdjacentHTML(
            "beforeend",
            '<span class="green"></span> Installation'
          );
          opt2.insertAdjacentHTML(
            "beforeend",
            '<span class="red"></span> Removal'
          );
          opt3.insertAdjacentHTML(
            "beforeend",
            '<span class="yellow"></span> Update'
          );
          select.appendChild(opt1);
          select.appendChild(opt2);
          select.appendChild(opt3);
          this.setValues();
        }
      });
    },
  },
  mounted() {
    this.myFiles = this.images;
    this.fileTypes = this.types;
  },
  watch: {
    tempfiles() {
      // console.log(this.tempfiles);
    },
  },
};
</script>
<style lang="scss">
.filepond-opened {
  height: 27rem;
}

.file-ponder {
  // padding-bottom: 7rem;
  .filepond--item {
    margin: 4px 8px 4px;
    border-bottom: 1px solid #eee;
    padding-bottom: 4px;
    height: auto !important;

    &:nth-child(1) {
      z-index: 10;
    }

    &:nth-child(2) {
      z-index: 9;
    }

    &:nth-child(3) {
      z-index: 8;
    }

    &:nth-child(4) {
      z-index: 7;
    }

    &:nth-child(5) {
      z-index: 6;
    }

    &:nth-child(6) {
      z-index: 5;
    }

    &:nth-child(7) {
      z-index: 4;
    }

    &:nth-child(8) {
      z-index: 3;
    }

    &:nth-child(9) {
      z-index: 2;
    }

    &:nth-child(10) {
      z-index: 1;
    }

    /*-moz-animation-direction: ;.filepond--file [data-align*="right"] {
      right: 3px;
      padding: 3px;
    }*/
  }

  .filepond--item-panel {
    background-color: transparent !important;
  }

  .filepond--panel-bottom {
    box-shadow: none !important;
  }

  .filepond--list {
    width: 100%;
    left: 0px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .file-pond-select {
    color: #000;
    position: absolute;
    right: 25%;
    width: 7rem;
    border: 1px solid #e3d7d7;
    border-radius: 5px;
  }

  .file-pond-select:focus-visible {
    outline: none;
  }

  .uploaded_file {
    display: flex;
    font-size: 11px;
    color: #5f6368;
    align-items: center;
    margin-top: 5px;
    justify-content: space-between;

    span {
      display: flex;
      align-items: center;
    }
  }

  .mySelect {
    color: #000;
    position: absolute;
    right: 10%;
    width: 7rem;
  }

  .brd {
    border: 1px solid #e2eded;
    border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
  }

  #info {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    color: #2d3667;
    font-size: 16px;
    text-align: center;
    padding: 14px;
    background-color: #f3f9f9;
  }

  #app-cover {
    position: absolute;
    top: 0px;
    right: 175px;
    left: auto;
    width: 130px;
    height: 42px;

    .green {
      color: #0f9d58;
    }

    .red {
      color: #db4437;
    }

    .yellow {
      color: #f4b400;
    }
  }

  #select-button {
    position: relative;
    height: 28px;
    padding: 12px;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }

  .options-view-button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  #selected-value {
    font-size: 12px;
    line-height: 1;
    margin-right: 7px;
    margin-top: -5px;
    margin-left: -5px;
  }

  .option i {
    width: 16px;
    height: 16px;
  }

  .option,
  .label {
    color: #2d3667;
    font-size: 16px;
  }

  #chevrons {
    position: absolute;
    top: -13px;
    right: 10px;
    bottom: 0;
    width: 12px;
    padding: 9px 14px;
  }

  #chevrons i {
    display: block;
    height: 50%;
    color: #d1dede;
    font-size: 24px;
    text-align: right;
  }

  .options-view-button:checked + #select-button #chevrons i {
    color: #2d3667;
  }

  .options {
    position: absolute;
    left: 0;
    width: 250px;
  }

  #options {
    position: absolute;
    top: 42px;
    right: 0;
    left: 0;
    width: 298px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 4px;
  }

  .options-view-button:checked ~ #options {
    border: 1px solid #e2eded;
    border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
  }

  .option {
    position: relative;
    line-height: 1;
    transition: 0.3s ease all;
    z-index: 2;
  }

  .option i {
    position: absolute;
    left: 14px;
    padding: 0;
    display: none;
  }

  .options-view-button:checked ~ #options .option i {
    display: block;
    padding: 12px 0;
  }

  .label {
    display: none;
    padding: 0;
    margin-left: 27px;
  }

  .options-view-button:checked ~ #options .label {
    display: block;
    padding: 12px 14px;
  }

  .s-c {
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
  }

  .s-c.top {
    top: 0;
  }

  .s-c.bottom {
    bottom: 0;
  }

  input[type="radio"] {
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    height: 50%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }

  .s-c:hover ~ i {
    color: #fff;
    opacity: 1;
  }

  .s-c:hover {
    height: 100%;
    z-index: 1;
  }

  .s-c.bottom:hover + i {
    //bottom: 15px;
    animation: moveup 0.3s ease 0.1s forwards;
  }

  .s-c.top:hover ~ i {
    // top: 0px;
    animation: movedown 0.3s ease 0.1s forwards;
  }

  .label {
    transition: 0.3s ease all;
  }

  .opt-val {
    position: absolute;
    left: 5px;
    width: 100px;
    height: 23px;
    opacity: 0;
    background-color: #fff;
    transform: scale(0);
  }

  .option input[type="radio"]:checked ~ #options {
    display: none;
  }

  .option input[type="radio"]:checked ~ .opt-val {
    opacity: 1;
    transform: scale(1);
  }

  .option input[type="radio"]:checked + #options {
    display: none;
  }

  .option input[type="radio"]:checked ~ i {
    top: 0;
    bottom: auto;
    opacity: 1;
    animation: unset;
  }

  .option input[type="radio"]:checked ~ i,
  .option input[type="radio"]:checked ~ .label {
    color: #000;
  }

  .option input[type="radio"]:checked ~ .label:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: none;
  }

  .options-view-button:not(:checked)
    ~ #options
    .option
    input[type="radio"]:checked
    ~ .opt-val {
    top: -38px;
  }

  .option:nth-child(1) input[type="radio"]:checked ~ .label:before {
    background-color: #000;
    border-radius: 4px 4px 0 0;
  }

  .option:nth-child(1) input[type="radio"]:checked ~ .opt-val {
    top: -38px;
  }

  .option:nth-child(2) input[type="radio"]:checked ~ .label:before {
    background-color: #ea4c89;
  }

  .option:nth-child(2) input[type="radio"]:checked ~ .opt-val {
    top: -77px;
  }

  .option:nth-child(3) input[type="radio"]:checked ~ .label:before {
    background-color: #0057ff;
  }

  .option:nth-child(3) input[type="radio"]:checked ~ .opt-val {
    top: -117px;
  }

  #option-bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 40px;
    transition: 0.3s ease all;
    z-index: 1;
    display: none;
  }

  .options-view-button:checked ~ #options #option-bg {
    display: block;
  }

  .option:hover .label {
    color: #000;
  }

  .option:nth-child(1):hover ~ #option-bg {
    top: 0;
    background-color: #fcfcfc;
    border-radius: 4px 4px 0 0;
  }

  .option:nth-child(2):hover ~ #option-bg {
    top: 40px;
    background-color: #fcfcfc;
  }

  .option:nth-child(3):hover ~ #option-bg {
    top: 80px;
    background-color: #fcfcfc;
  }

  .filepond--file {
    .filepond--file-status {
      margin-right: 2.5rem;
    }

    .filepond--file-action-button {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .filepond--file-info-main {
      width: 60%;
    }
  }
}
</style>
