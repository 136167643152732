import Vue from "vue";
import axios from "axios";

const state = {
    shipments: [],
    shipment_info: {},
    response: {},
    checkresponse:"",
};
const getters = {
    getShipments(state) {
        return state.shipments;
    },
    getShipmentInfo(state) {
        return state.shipment_info
    },
    getResponse(state) {
        return state.response;
    },
    getcheckresponse(state) {
        return state.checkresponse;
      },
};

const mutations = {
    setShipments(state, data) {
        state.shipments = data.data;
    },
    setShipmentInfo(state, data) {
        state.shipment_info = data.data;
    },
    setResponse(state, data) {
        state.response = data;
    },
    setcheckresponse(state, data) {
        state.checkresponse = data.data;
      },
};
const actions = {
    storeShipment(context, data) {
        return axios
            .post("/api/warehouse/create-shipment", data)
            .then((response) => {
                context.commit("setResponse", { data: response.data });
            })
            .catch((error) => {
                // context.commit("setResponse", { data: [error] });
                context.commit("setcheckresponse", {
                    data: error.response,
                  });
            });
    },
    loadShipments(context) {
        return axios
            .get("/api/warehouse/get-all-shipment")
            .then((response) => {
                context.commit("setShipments", { data: response.data });
            })
            .catch((error) => {
                context.commit("setResponse", { data: [error] });
            });
    },
    shipmentInfo(context, id) {
        return axios
            .get("/api/warehouse/shipment/" + id)
            .then((response) => {
                context.commit("setShipmentInfo", { data: response.data });
            })
            .catch((error) => {
                context.commit("setResponse", { data: [error] });
            });
    }
};
export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
};
